import axios from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import { FaPlus } from "react-icons/fa";
import { LiaPalletSolid } from "react-icons/lia";
import ModalConfirm from "../../components/ModalConfirm";

//Interface para receber as informações por parametros
interface ModalPadraoProps {
    isOpen: boolean;
    fecharModal: () => void;
    processo: number;
    WMS: number;
    codigo: string;
    tipoPeso: string;
    descricao: string;
    token: string;
    idFilial: string;
    usuarioLogado: string;
    idCliente: number;
    gtinEntradaTodos: boolean;
    gtinEntrada: boolean;
    unFiscal: string;
    qtdeFiscal: number;
    exibirQtdeNF: boolean;
    aprovaValVencida: boolean;
}

const ModalPadrao: React.FC<ModalPadraoProps> = ({
    isOpen,
    fecharModal,
    processo,
    WMS,
    codigo,
    tipoPeso,
    descricao,
    token,
    idFilial,
    usuarioLogado,
    idCliente,
    gtinEntradaTodos,
    gtinEntrada,
    unFiscal,
    qtdeFiscal,
    exibirQtdeNF,
    aprovaValVencida
}) => {
    const [validade, setValidade] = useState<string>('')
    const [UA, setUA] = useState<number | string>()
    const [qtdeVol, setQtdeVol] = useState<number | string>()
    const [lote, setLote] = useState<number | string>()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [disabledGtin, setDisabledGtin] = useState<boolean>(false)
    const [unidades, setUnidades] = useState<string[]>([])
    const [unidadeSelecionada, setUnidadeSelecionada] = useState<string>('')
    const [fator, setFator] = useState<number>(0)
    const [idEmb, setIdEmb] = useState<number>(0)
    const [gtin, setGtin] = useState<string>()
    const [viewLote, setViewLote] = useState<boolean>(false)
    const [unidCarregada, setUnidCarregada] = useState<string>()
    const [mostraUnidCarregada, setMostraUnidCarregada] = useState<boolean>(true)
    const [viewQtdeNF, setViewQtdeNF] = useState<boolean>(true)
    const [mostraModalConfirmValVencida, setMostraModalConfirmVal] = useState<boolean>(false)
    const [ocultaModal, setOcultaModal] = useState<boolean>(false)

    const UARef = useRef(null)
    const qtdeVolRef = useRef(null)
    const validadeRef = useRef(null)
    const loteRef = useRef(null)
    const btnGravarRef = useRef<HTMLButtonElement>(null)
    const unidadeRef = useRef(null)
    const gtinRef = useRef(null)

    const navigate = useNavigate()

    function consultaUA(ref: any, qtdeVolRef: any) {
        if (!UA) {
            setQtdeVol("")
            setValidade("")
            setLote("")
            setDisabledGtin(false)
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/UA/confEntrada/${UA}/${processo}/${WMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDisabledGtin(false)
            if (resposta.data == 'UA existe como vazia') {
                setQtdeVol("")
                setValidade("")
                setLote("")
                qtdeVolRef.current.focus();
                return
            } else {
                setQtdeVol(resposta.data.qtdeVol)
                const date = new Date(resposta.data.validade)
                let validadeUA = date.toISOString().substring(0, 10).replace(/-/g, '/')
                let [year, month, day] = validadeUA.split('/')
                setValidade(`${day}/${month}/${year}`)
                setLote(resposta.data.lote)
                btnGravarRef.current?.focus()
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                setUA("")
                setQtdeVol("")
                setValidade("")
                setLote("")
                ref.current.focus()
                toast.error(erro.response.data)
            }
        })
    }

    async function gravarUA(ref: any) {

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/UA/padrao?idFilial=${idFilial}`, {
            UA,
            qtdeVol: qtdeVol ? qtdeVol : 1,
            validade,
            lote,
            processo,
            codigo,
            usuarioLogado,
            WMS,
            fator,
            idEmb,
            unidadeSelecionada: unidadeSelecionada && unidadeSelecionada !== 'Selecione' ? unidadeSelecionada : unidCarregada
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            ref.current.focus()
            setUA("")
            setQtdeVol("")
            setValidade("")
            setLote("")
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            else {
                setUA("")
                setQtdeVol("")
                setValidade("")
                setLote("")
                setUnidadeSelecionada("Selecione")
                setFator(0)
                setIdEmb(0)
                setGtin('')
                ref.current.focus()
                toast.error(erro.response.data)
            }
        })
    }

    function verificaValidadeECampos() {
        if (!UA || !validade) {
            toast.info("Preencha todos os campos para gravar a UA")
            return
        } else if (validade.length != 10) {
            toast.info("Preencha a validade corretamente para prosseguir")
            return
        } else if (gtinEntrada === true && !gtin) {
            toast.info("Obrigatório inserir Gtin")
            return
        } else if (gtinEntrada === false && (!unidadeSelecionada && !unidCarregada)) {
            toast.info("Preencha unidade para gravar UA")
            return
        }

        const [day, month, year] = validade.split('/')
        const newVal = new Date(`${year}-${month}-${day}`)
        const hoje = new Date()

        if (aprovaValVencida && newVal < hoje) {
            setMostraModalConfirmVal(true)
            setOcultaModal(true)
        } else if (newVal < hoje) {
            return toast.info("A validade inserida está vencida")
        } else {
            gravarUA(UARef)
        }
    }

    function criarNovaUA(ref: any, qtdeVolRef: any) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/nova/UA?idFilial=${idFilial}`, {
            processo,
            usuarioLogado,
            WMS,
            codigo
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setUA(resposta.data.UA)
            setQtdeVol("")
            setValidade("")
            setLote("")
            setDisabledGtin(false)
            qtdeVolRef.current.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            else {
                toast.error(erro.response.data)
                ref.current.focus()
            }
        })
    }

    function consultaProduto() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/produto/${codigo}/${idCliente}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data.data[0].controla_lote) {
                setViewLote(false)
            } else {
                setViewLote(true)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            toast.error(erro.response.data)
        })
    }

    function buscaUnidEmb() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/unidades/${idCliente}/${codigo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setUnidades(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            toast.error(erro.response.data)
        })
    }

    async function lerGtin(ref: any) {
        if (!gtin) {
            setFator(0)
            setIdEmb(0)
            setUnidCarregada("")
            setUnidadeSelecionada("Selecione")
            setMostraUnidCarregada(true)
            return
        }

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/confere/gtin/${gtin}/${WMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setIdEmb(resposta.data.data[0].id_emb)
            setFator(resposta.data.data[0].fator)
            setUnidCarregada(resposta.data.data[0].unid_manuseio)
            setMostraUnidCarregada(false)
            setDisabledGtin(true)

            // if (gtinEntrada && gtinEntradaTodos) {
            //     gravarUA(ref, resposta.data.data[0].fator, resposta.data.data[0].id_emb, resposta.data.data[0].unid_manuseio)
            // }
            // ref.current.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            toast.error(erro.response.data.message)
            setFator(0)
            setIdEmb(0)
            setUnidCarregada("")
            setMostraUnidCarregada(true)
            ref.current.focus()
        })
    }

    const handleValidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Remove todos os caracteres não numéricos
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 8) {
            // Limita a entrada a 8 caracteres
            input = input.slice(0, 8);
        }

        if (input.length <= 2) {
            input = input;
        } else if (input.length <= 4) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        } else {
            input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
        }

        setValidade(input);
    };

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    return (
        <>
            <Modal className="mt-3" size="lg" hidden={ocultaModal} show={isOpen} onShow={() => {
                setDisabledGtin(false)
                setUA("")
                setQtdeVol("")
                setValidade("")
                setLote("")
                consultaProduto()
                buscaUnidEmb()
                setMostraUnidCarregada(true)
                setGtin("")
                setFator(0)
                setIdEmb(0)
                setUnidCarregada("")
                setUnidadeSelecionada("Selecione")

                if (exibirQtdeNF) {
                    setViewQtdeNF(false)
                } else {
                    setViewQtdeNF(true)
                }

                if (gtinEntrada === true && gtinEntradaTodos === true) {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Padrão"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="justify-content-between">
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                autoFocus
                                value={UA}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                ref={UARef}
                                onKeyDown={(event) => handleKeyDown(event, qtdeVolRef)}
                                onChange={(event) => setUA(event.target.value)}
                                onBlur={() => consultaUA(UARef, qtdeVolRef)}
                            />
                            <label className="form-label">NÚMERO U.A</label>
                        </div>

                        <div className="col-6 mt-2">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded fs-5"
                                onClick={() => criarNovaUA(UARef, qtdeVolRef)}
                            >
                                <span className='me-2'>Criar U.A.</span> <LiaPalletSolid className="mb-1" size={20} />

                            </button>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={qtdeVol}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                ref={qtdeVolRef}
                                disabled={disabled}
                                onKeyDown={(event) => handleKeyDown(event, unidadeRef)}
                                onChange={(event) => setQtdeVol(event.target.value)}
                            />
                            <label className="form-label">QUANTIDADE</label>
                        </div>

                        <div className="col-6 form-floating" hidden={!mostraUnidCarregada}>
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setUnidadeSelecionada(event.target.value)

                                    const unid: any = unidades.filter((item: any) => item.unid_manuseio === event.target.value)

                                    if (unid && unid.length !== 0) {
                                        setIdEmb(unid[0].id_emb)
                                        setFator(unid[0].fator)
                                    }
                                }}
                                ref={unidadeRef}
                                onKeyDown={(event) => handleKeyDown(event, loteRef)}
                                value={unidadeSelecionada}
                            >
                                <option>Selecione</option>

                                {unidades.map((unidade: any) => (
                                    <option>{unidade.unid_manuseio}</option>
                                ))}
                            </select>
                            <label className="form-label">UNID. EMBALAGEM</label>
                        </div>

                        <div className="col-6 form-floating mt-1" hidden={mostraUnidCarregada}>
                            <input
                                type="text"
                                value={unidCarregada}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">UNID. EMBALAGEM</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={fator}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                maxLength={10}
                                disabled
                            />
                            <label className="form-label">FATOR</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={idEmb}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                maxLength={10}
                                disabled
                            />
                            <label className="form-label">ID EMBALAGEM</label>
                        </div>
                    </Row>


                    <Row>
                        <div className="col-6 form-floating mt-1" hidden={viewLote}>
                            <input
                                type="number"
                                value={lote}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(event) => setLote(event.target.value)}
                                ref={loteRef}
                                onKeyDown={(event) => handleKeyDown(event, validadeRef)}
                            />
                            <label className="form-label">LOTE</label>
                        </div>

                        <div className={`${viewLote === false ? 'col-6' : 'col-12'} form-floating mt-1`}>
                            <input
                                type="text"
                                value={validade}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={handleValidadeChange}
                                maxLength={10}
                                ref={validadeRef}
                                onKeyDown={(event) => handleKeyDown(event, gtinRef)}
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                type="number"
                                value={gtin}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(event) => setGtin(event.target.value)}
                                ref={gtinRef}
                                disabled={disabledGtin}
                                onKeyDown={(event) => handleKeyDown(event, btnGravarRef)}
                                onBlur={() => {
                                    lerGtin(gtinRef)
                                }}
                            />
                            <label className="form-label">GTIN</label>
                        </div>
                    </Row>

                    <div className="mt-2">
                        <button
                            type="button"
                            className="w-100 btn btn-novo rounded fs-5"
                            ref={btnGravarRef}
                            onClick={verificaValidadeECampos}
                        >
                            <span className='me-2'>Gravar U.A</span> <FaPlus className="mb-1" size={18} />
                        </button>
                    </div>

                    <Row className="mt-2 mb-2 border border-primary"></Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={processo}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">PROCESSO</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={WMS}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">ID WMS</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={codigo}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={tipoPeso}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">TIPO DE PESO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1" hidden={viewQtdeNF}>
                            <input
                                type="number"
                                value={qtdeFiscal}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">TOTAL DAS NOTAS</label>
                        </div>

                        <div className={`${viewQtdeNF ? 'col-12' : 'col-6'} form-floating mt-1`}>
                            <input
                                type="text"
                                value={unFiscal}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">UN. FISCAL</label>
                        </div>
                    </Row>

                    <Row className="mb-2">
                        <div className="col form-floating mt-1">
                            <input
                                type="text"
                                value={descricao}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">DESCRIÇÃO</label>
                        </div>
                    </Row>

                </Modal.Body>

                <ModalConfirm
                    isOpen={mostraModalConfirmValVencida}
                    fecharModal={() => {
                        setMostraModalConfirmVal(false)
                        setOcultaModal(false)
                    }}
                    mensagem="A validade inserida está vencida, deseja aprovar para prosseguir?"
                    onConfirm={gravarUA}
                    params={UARef}
                    mostrarMoldal={setOcultaModal}
                />
            </Modal>
        </>
    )
}

export default ModalPadrao