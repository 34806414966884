import axios from "axios";
import { Button, Card, Modal, Row } from "react-bootstrap";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import ClickableTable from "../../../../components/ClickTable/ClickTable";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";

function GtinCargaHistorico() {

    const navigate = useNavigate()

    const token = getSessionData("MultfilialWebToken") ?? "";
    const idFilial = getSessionData("DadosIdfilial") ?? "";

    const [processo, setProcesso] = useState<number>()
    const [data, setData] = useState([])
    const [mostrarModalCarregando, setMostrarModalCarregando] = useState<boolean>(false)
    const [filtroSelecionado, setFiltroSelecionado] = useState<string>("os")
    const [msgFiltro, setMsgFiltro] = useState<string>("Pesquise pela OS")

    async function carregaGtinHistorico() {
        if (!processo) {
            setData([])
            return
        }

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/gtin/historico/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setData(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setData([])
            }
        })
    }

    function gerarExcel() {
        setMostrarModalCarregando(true);
        if (data.length === 0) {
            toast.error("Dados não encontrados");
        } else {
            // Criar uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([]);

            // Definir o cabeçalho
            const header = ["Cliente", "Processo", "O.S", "Pedido", "Código", "Gtin", "Cadastro", "Usuário"];
            XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

            // Adicionar os dados dos usuários ao array
            const dataRows = data.map((item: any) => [
                item.cliente,
                item.processo,
                item.os,
                item.pedido,
                item.codigo,
                item.gtin,
                item.cadastro,
                item.usuario,
            ]);

            // Adicionar os dados ao arquivo Excel
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: "A2" });

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new();

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, 'HistóricoLeituraGtin.xlsx');
        }
        setMostrarModalCarregando(false);
    }


    const colunas: ITabela[] = [
        { titulo: "Depositante", acesso: "cliente" },
        { titulo: "Processo", acesso: "processo" },
        { titulo: "O.S", acesso: "os" },
        { titulo: "Pedido", acesso: "pedido" },
        { titulo: "Código", acesso: "codigo" },
        { titulo: "Gtin", acesso: "gtin" },
        { titulo: "Dt. Leitura", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuario" }
    ];

    return (
        <>

            <Card className="mt-3">
                <Card.Body>

                    <Row>
                        <div className="col-6 form-floating">
                            <input
                                type="number"
                                placeholder="Insira o número do processo"
                                value={processo}
                                className="form-control bordasInferiorInput text-black"
                                autoFocus
                                onChange={(event) => {
                                    setProcesso(parseInt(event.target.value))
                                }}
                                onBlur={carregaGtinHistorico}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        (e.target as HTMLInputElement).blur()
                                    }
                                }}
                            />
                            <label className="form-label">Processo</label>
                        </div>
                        
                        <div className="col-6 form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                value={filtroSelecionado}
                                style={{ height: '1.6cm' }}
                                onChange={(event) => {
                                    setFiltroSelecionado(event.target.value)
                                    let filtro = event.target.value

                                    if (filtro === "pedido") {
                                        setMsgFiltro("Pesquise pelo pedido")
                                    } else {
                                        setMsgFiltro("Pesquise pela OS")
                                    }
                                }}
                            >
                                <option value="">Selecione</option>
                                <option value="os">OS</option>
                                <option value="pedido">Pedido</option>
                            </select>
                            <label className="form-label">SELECIONE O FILTRO DA TABELA</label>
                        </div>
                    </Row>

                    {/* <Row className="mt-3">
                        
                    </Row> */}

                    <Row className="mt-2">
                        <ClickableTable
                            data={data}
                            itemsPerPage={10}
                            coluna={colunas}
                            onRowClick={function () { }}
                            labelpesquisa={msgFiltro}
                            acessopesquisa={filtroSelecionado}
                        />
                    </Row>


                    <button
                        className="btn btn-success me-2"
                        onClick={() => {
                            gerarExcel()
                        }}
                    >
                        <i className="bi bi-file-earmark-excel"></i> Gerar Excel
                    </button>

                </Card.Body>

            </Card>

            <ModalCarregando
                mostrar={mostrarModalCarregando}
                mensagem="Carregando dados para fazer download"
            />

        </>
    )
}

export default GtinCargaHistorico