import { Card, Row } from "react-bootstrap"
import Tabela from "../../../../components/Formularios/Table"
import { useNavigate, useParams } from "react-router-dom";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { useEffect, useRef, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";

function LerGtin() {
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_baixar, setPermissaoBaixar] = useState(true)
    const [rgGtin, setRgGtin] = useState<any>()
    const [dados, setDados] = useState([])
    const [totalGtin, setTotalGtin] = useState<number>()
    const [gtinLido, setGtinLido] = useState<number>()

    const navigate = useNavigate()
    const params = useParams()
    const OS = params.OS
    const processo = params.processo

    const RGGtinRef = useRef(null)

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    //Funções para controle de ações da tabela
    const editarUsuario = () => { };
    const excluirUsuario = () => { };
    const visualizarTabelaOS = () => { };
    const btnBaixarOS = () => { }

    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "OS", acesso: "os" },
        { titulo: "Código", acesso: "codigo" },
        { titulo: "Qtde. Pedido", acesso: "linhas_pedido" },
        { titulo: "Qtde. Conferido", acesso: "linhas_conferido" },
    ];

    function gravarRgGtin(ref: any) {
        if (rgGtin == 0 || rgGtin == null || rgGtin == undefined) {
            toast.info('É necessário realizar a leitura de Gtin')
            return;
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/rgGtin/?idFilial=${idFilial}`, {
            usuarioLogado,
            OS,
            rgGtin
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            // consultaQtdeLidoETotal()
            carregaGrid()
            setRgGtin("")
            ref.current?.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.error(erro.response.data)
                setRgGtin("")
                ref.current.focus()
            }
        })
    }

    function carregaGrid() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/grid/gtin/${OS}/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)

            let totalPedido = 0
            let totalConferido = 0
            for (let i = 0; i < resposta.data.data.length; i++) {
                totalPedido += resposta.data.data[i].linhas_pedido
                totalConferido += resposta.data.data[i].linhas_conferido
            }
            setTotalGtin(totalPedido)
            setGtinLido(resposta.data.qtdeLido[0].linhas)
            // console.log(totalPedido)
            // console.log(totalConferido)
            if (totalPedido == totalConferido) {
                navigate("/conferencia/saida")
            }
            
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.log(erro)
            }
        })
    }

    // function consultaQtdeLidoETotal() {
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/qtde/gtin/${processo}/${OS}?idFilial=${idFilial}`, {
    //         headers: {
    //             Authorization: token
    //         }
    //     }).then(function (resposta) {
    //         setTotalGtin(resposta.data.totalGtin)
    //         setGtinLido(resposta.data.totalLido)
    //     }).catch(function (erro) {
    //         const status = erro.response.status
    //         if (status == 403 || status == 401) {
    //             toast.error("Acesso negado, faça seu login novamente")
    //             navigate("/login")
    //         } else {
    //             console.log(erro)
    //         }
    //     })
    // }

    useEffect(() => {
        carregaGrid()
        // consultaQtdeLidoETotal()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="col-2">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                type="number"
                                value={totalGtin}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">TOTAL GTIN</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                type="number"
                                value={gtinLido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">GTIN LIDO</label>
                        </div>
                    </Row>
                    <div className="mt-2 d-flex align-items-center">
                        <label htmlFor="Local Sugestao" className="me-2" style={{ minWidth: '80px', maxWidth: '80px' }}>Gtin volume</label>
                        <input
                            type="number"
                            autoFocus
                            id="U.A."
                            value={rgGtin}
                            className="me-2 w-75"
                            style={{ height: '30px' }}
                            ref={RGGtinRef}
                            onChange={(event) => {
                                setRgGtin(event.target.value)
                            }}
                            onBlur={() => {
                                gravarRgGtin(RGGtinRef)
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    gravarRgGtin(RGGtinRef)
                                }
                            }}
                        />
                    </div>

                    <Row className="mt-3">
                        <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            onBaixarClick={btnBaixarOS}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            permissao_baixar={permissao_baixar}
                        />
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default LerGtin