import axios from "axios";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getSessionData } from "../../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";
import Tabela from "../../../../components/Formularios/Table";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { mdiConsoleNetworkOutline } from "@mdi/js";
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";

interface ModalConsultaBarrasProps {
    isOpen: boolean;
    fecharModal: () => void;
}

const ModalConsultaBarras: React.FC<ModalConsultaBarrasProps> = ({
    isOpen,
    fecharModal
}) => {
    const [barra, setBarra] = useState<any>()
    const [UA, setUA] = useState<any>()
    const [depositante, setDepositante] = useState<any>()
    const [codigo, setCodigo] = useState<any>()
    const [descricao, setDescricao] = useState<any>()
    const [pesoLiq, setPesoLiq] = useState<any>()
    const [validade, setValidade] = useState<any>()
    const [endereco, setEndereco] = useState<any>()
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)

    const navigate = useNavigate();

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    function consultaBarras() {
        if (barra == "" || barra == 0) {
            // toast.info("Insira uma barra válida")
            setBarra("")
            setUA("")
            setDepositante("")
            setCodigo("")
            setDescricao("")
            setPesoLiq("")
            setValidade("")
            setEndereco("")
            return;
        }

        setIsProcessing(true)
        setMostraModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/acoes/consulta/barra/${barra}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setUA(resposta.data.infoBarra[0].id_ua)
            setDepositante(resposta.data.infoPallet[0].fantasia)
            setCodigo(resposta.data.infoBarra[0].codigo)
            setDescricao(resposta.data.infoBarra[0].descricao)
            const pesoLiquido = resposta.data.infoBarra[0].pl
            setPesoLiq(pesoLiquido.toFixed(2).replace('.', ','))
            const validade = resposta.data.infoBarra[0].validade_menor
            setValidade(validade.substring(0, 10))
            setEndereco(resposta.data.infoPallet[0].Endereco)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setBarra("")
                setUA("")
                setDepositante("")
                setCodigo("")
                setDescricao("")
                setPesoLiq("")
                setValidade("")
                setEndereco("")
            }
        }).finally(function () {
            setIsProcessing(false)
            setMostraModalCarregando(false)
        })
    }


    return (
        <>
            <Modal
                show={isOpen}
                className='mt-3'
                onShow={() => {
                    setBarra("")
                    setUA("")
                    setDepositante("")
                    setCodigo("")
                    setDescricao("")
                    setPesoLiq("")
                    setValidade("")
                    setEndereco("")
                }}
            >
                <Modal.Header className="bg-white d-flex justify-content-between align-items-center" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Consultar Barra"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            autoFocus
                            value={barra}
                            className="form-control bordasInferiorInput text-black"
                            onChange={(event) => {
                                setBarra(event.target.value)
                            }}
                            onBlur={() => {
                                if (!isProcessing) {
                                    consultaBarras()
                                }
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && !isProcessing) {
                                    consultaBarras()
                                }
                            }}
                        />
                        <label className="form-label">BARRA</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={UA}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">U.A</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={depositante}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">DEPOSITANTE</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={codigo}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">CÓDIGO</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={descricao}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">DESCRIÇÃO</label>
                    </div>

                    <Row className="mt-3 border border-primary"></Row>

                    <Row>
                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={pesoLiq}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">PESO LÍQUIDO</label>
                        </div>

                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={validade}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>
                    </Row>

                    <div className="col form-floating mt-2">
                        <input
                            // type="text"
                            value={endereco}
                            className="form-control bordasInferiorInput text-black"
                            disabled
                        />
                        <label className="form-label">ENDEREÇO</label>
                    </div>

                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="mb-2 d-flex justify-content-center w-100">
                        <button
                            type="button"
                            className="btn btn-cancelar me-2 w-50"
                            data-dismiss="modal"
                            onClick={() => {
                                fecharModal()
                            }}
                        >
                            Voltar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalCarregando
                mensagem="Carregando..."
                mostrar={mostraModalCarregando}
            />
        </>
    )
}

export default ModalConsultaBarras