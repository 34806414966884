import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/img/principal.jpg";
import { getSessionData } from "../utils/storageUtils";
import ApiGet from "../api/endPoints/useGet";
import { useEffect, useState } from "react";
import logo4 from '../assets/img/logoLeftBar.png';
import logoSM from '../assets/img/logo_sm.png';
import axios from "axios";
import { toast } from "react-toastify";
import ModalSelecionaReposicao from "./menuPrincipal/reposicaoPicking/components/modalSelecionaReposicao";

const LeftBar = (): JSX.Element => {
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil") || ""
  const token = getSessionData("MultfilialWebToken") || "";
  const idFilial = getSessionData("DadosIdfilial") || ""
  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(false)
  const [mostraModalSelecionaReposicao, setMostraModalSelecionaReposicao] = useState<boolean>(false)

  const [viewUsuario, setViewUsuario] = useState<boolean>(true)
  const [viewUsuariosFilial, setViewUsuariosFilial] = useState<boolean>(true)
  const [viewCodSistema, setViewCodSistema] = useState<boolean>(true)
  const [viewConfSaida, setViewConfSaida] = useState<boolean>(true)
  const [viewConfEntrada, setViewConfEntrada] = useState<boolean>(true)
  const [viewEnderecar, setViewEnderecar] = useState<boolean>(true)
  const [viewPicking, setViewPicking] = useState<boolean>(true)
  const [viewRessuprimento, setViewRessuprimento] = useState<boolean>(true)
  const [viewDesfragmentar, setViewDesfragmentar] = useState<boolean>(true)
  const [viewInventario, setViewInventario] = useState<boolean>(true)
  const [viewAdm, setViewAdm] = useState<boolean>(true)
  const [viewMonitoramento, setViewMonitoramento] = useState<boolean>(true)

  //Monitorar a largura da janela e atualizar o estado isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  async function verificaPermissoes() {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/permissoes/${id_perfil}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {
      const resp = resposta.data.data[0]

      if (
        !resp.visualizarUsuario &&
        !resp.visualizarUsuariosFilial &&
        !resp.visualizarCodSistema &&
        parseInt(id_perfil) !== 1
      ) {
        setViewAdm(true)
      } else {
        setViewAdm(false)
      }

      setViewUsuario(!resp.visualizarUsuario)
      setViewUsuariosFilial(!resp.visualizarUsuariosFilial)
      setViewCodSistema(!resp.visualizarCodSistema)
      setViewConfSaida(!resp.visualizarConferirCarregamento)
      setViewConfEntrada(!resp.visualizarConferirRecebimento)
      setViewDesfragmentar(!resp.visualizarDesfragmentarUA)
      setViewEnderecar(!resp.visualizarEnderecarUA)
      setViewInventario(!resp.visualizarInventario)
      setViewPicking(!resp.visualizarProdutosPicking)
      setViewMonitoramento(!resp.visualizarPainelMonitoramento)

      if (resp.visualizarReposicaoComplementar && resp.visualizarReposicaoPrimaria) {
        setViewRessuprimento(false)
      } else {
        setViewRessuprimento(true)
      }
    }).catch(function (erro) {
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
        navigate("/login")
      } else if (status == 406) {
        toast.info(erro.response.data)
      }
    })
  }

  useEffect(() => {
    verificaPermissoes()
  }, [])

  return (
    <>
      <div hidden={isMobile} className="leftside-menu" style={{ backgroundColor: 'white', zIndex: 1050 }}>
        <button className="logo text-center border-0" onClick={() => { }}>
          <span className="logo-lg" style={{ backgroundColor: 'white' }}>
            <Link
              to="/menu/menuPrincipal"
              className="border-0 align-items-center"
              onClick={() => {
                sessionStorage.removeItem("Processo")
                sessionStorage.removeItem("ProcessoEntrada")
              }}
            >
              <img src={logo4} alt="" />
            </Link>

          </span>
          <span className="logo-sm">
            <Link
              to="/menu/menuPrincipal"
              className="border-0 align-items-center"
              onClick={() => {
                sessionStorage.removeItem("Processo")
                sessionStorage.removeItem("ProcessoEntrada")
              }}
            >
              <img src={logoSM} alt="" />
            </Link>
          </span>
        </button>

        <hr className="m-0 mb-3" />

        <div className="h-100" id="leftside-menu-container" data-simplebar>
          <ul className="side-nav">
            {/* Menu Administrativo */}
            <li hidden={viewAdm} className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#administrativoMenu"
                aria-expanded="false"
                aria-controls="administrativoMenu"
                className="side-nav-link text-dark d-flex align-items-center"
              >
                <i className="mdi mdi-account-circle me-2" style={{ fontSize: '25px' }}></i>
                <span style={{ fontWeight: 'bold' }} data-width="22">Administrativo</span>
                <span className="menu-arrow text-dark ms-auto"></span>
              </a>
              <div className="collapse" id="administrativoMenu">
                <ul className="side-nav-second-level">
                  <li hidden={parseInt(id_perfil) === 1 ? false : true}><Link to="/cadastro/filiais/filial" className="text-dark">Filiais</Link></li>
                  <li hidden={viewUsuario}><Link to="/administrativo/usuario" className="text-dark">Usuário</Link></li>
                  <li hidden={viewUsuariosFilial}><Link to="/grid/filial" className="text-dark">Usuarios/Filial</Link></li>
                  {/* <li hidden={viewCodSistema}><Link to="/administrativo/codistema" className="text-dark">Código do Sistema</Link></li> */}

                  {/* Painel de Monitoramento com menu colapsável */}
                  <li hidden={viewMonitoramento} className="side-nav-item">
                    <a
                      data-bs-toggle="collapse"
                      href="#painelMonitoramentoMenu"
                      aria-expanded="false"
                      aria-controls="painelMonitoramentoMenu"
                      className="side-nav-link text-dark d-flex align-items-center"
                    >
                      <span>Painel de Monitoramento</span>
                      <span className="menu-arrow ms-auto"></span>
                    </a>
                    <div className="collapse" id="painelMonitoramentoMenu">
                      <ul className="side-nav-second-level">
                        <li>
                          <a
                            onClick={() => window.open(`/administrativo/painelMonitoramento/dados/gtin/${token}/${idFilial}`, '_blank')}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            className="text-dark"
                          >
                            Leitura Gtin Carga - Em Andamento
                          </a>
                        </li>
                        <li>
                          <Link to="/administrativo/painelMonitoramento/gtinCargaHistorico" className="text-dark">
                            Leitura Gtin Carga - Histórico
                          </Link>
                        </li>
                        <li>
                          <Link to="/menu/produtividadeGtin/admin" className="text-dark">
                            Produtividade Leitura Gtin Carga
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>

            <li className="side-nav-item mt-2">
              <a
                data-bs-toggle="collapse"
                href="#operacionalMenu"
                aria-expanded="false"
                aria-controls="operacionalMenu"
                className="side-nav-link text-dark d-flex align-items-center"
              >
                <i className="mdi mdi-cog me-2" style={{ fontSize: '25px' }}></i>
                <span style={{ fontWeight: 'bold' }} data-width="22">Operacional</span>
                <span className="menu-arrow text-dark ms-auto"></span>
              </a>
              <div className="collapse" id="operacionalMenu">
                <ul className="side-nav-second-level">
                  <li hidden={viewConfSaida}><Link to="/conferencia/saida" className="text-dark">Conferência de Saída</Link></li>
                  <li hidden={viewConfEntrada}><Link to="/conferencia/entrada" className="text-dark">Conferência de Entrada</Link></li>
                  <li hidden={viewEnderecar}><Link to="/menu/enderecar" className="text-dark">Endereçar</Link></li>
                  <li hidden={viewPicking}><Link to="/menu/picking" className="text-dark">Picking</Link></li>
                  <li hidden={viewRessuprimento}>
                    <a className="text-dark" onClick={() => setMostraModalSelecionaReposicao(true)}>Ressuprimento</a>
                  </li>
                  <li hidden={viewDesfragmentar}><Link to="/menu/desfragmentar" className="text-dark">Desfragmentar</Link></li>
                  <li hidden={viewInventario}><Link to="/menu/inventario" className="text-dark">Inventário</Link></li>
                  <li hidden={false}><Link to="/menu/produtividadeGtin/comum" className="text-dark">Produtividade Leitura Gtin</Link></li>
                  <li><Link to="/acoes/menuAcoes" className="text-dark">Outras Ações</Link></li>
                </ul>
              </div>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>

      <ModalSelecionaReposicao
        isOpen={mostraModalSelecionaReposicao}
        fecharModal={() => setMostraModalSelecionaReposicao(false)}
      />
    </>
  );
};

export { LeftBar };
