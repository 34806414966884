import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import Tabela from "../../../components/Formularios/Table"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import axios from "axios"
import { getSessionData } from "../../../utils/storageUtils"
import { FaArrowLeft, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';


function UsuariosFilial() {

    const navigate = useNavigate()
    const params = useParams()
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    const [dados, set_dados] = useState([])
    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    const [imagemFilial, set_imagemFilial] = useState("")
    function setImagemFilial(imagemBase64: any) {
        set_imagemFilial(imagemBase64.replace(/^"(.*)"$/, '$1'))
    }

    function gerarExcelUsuarios() {
        set_mostrarModalCarregando(true);
        if (dados.length === 0) {
            toast.error("Nenhum usuário encontrado");
        } else {
            // Criar uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([]);

            // Definir o cabeçalho
            const header = ["ID do Usuário", "Nome do Usuário", "Email", "Data de Cadastro", "Usuário que Cadastrou"];
            XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

            // Adicionar os dados dos usuários ao array
            const dataRows = dados.map((item: any) => [
                item.id_usuario,
                item.nome_usuario,
                item.email,
                item.data_cadastro,
                item.usuario_cadastro
            ]);

            // Adicionar os dados ao arquivo Excel
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: "A2" });

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new();

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, 'ListaUsuariosFilial.xlsx');
        }
        set_mostrarModalCarregando(false);
    }


    async function CarregarPermissoes(id_perfil: any) {

        set_mostrarModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_mostrarModalCarregando(false)
            set_permissao_visualizar(!resposta.data[0].visualizarUsuariosFilial)
        })
    }


    function carregarUsuarios(idFilial: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/usuarios/filial/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_dados(resposta.data)
        }).catch(function (erro) {
            toast.error(erro.response.data)
        })
    }

    useEffect(function () {
        carregarUsuarios(params.idFilial)
        setImagemFilial(getSessionData('imgFilial' || ""))
        CarregarPermissoes(getSessionData("id_perfil"))
    }, [])


    const colunas: ITabela[] = [
        { titulo: "", acesso: "avatar" },
        { titulo: "Id", acesso: "id_usuario" },
        { titulo: "Nome", acesso: "nome_usuario" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Data de Cadastro", acesso: "data_cadastro" },
        { titulo: "Usuário de Cadastro", acesso: "usuario_cadastro" },
    ];

    const actions = [
        {
            icon: <FaFilePdf />,
            name: "",
            onClick: function () {
                window.print();
            }
        },
        {
            icon: <FaFileExcel />,
            name: "Exportar para Excel",
            onClick: gerarExcelUsuarios
        },
        {
            icon: <FaArrowLeft />,
            name: "Voltar",
            onClick: () => navigate("/grid/filial")
        }
    ];

    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)

    const editarUsuario = () => { };
    const excluirUsuario = () => { };
    const visualizarUsuario = (dados: any) => {
        //1 = Para fazer o tratamento na tela visualizarUsuario para que o botão voltar, volte para essa tela
        navigate(`/administrativo/usuario/usuario/visu/${dados.id_usuario}?1`)
    };

    return (
        <>
            <div className="container-fluid mt-2 no-print">
                <ContainerTitulo titulo="Listagem de Usuários" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <div className="mb-2">
                                        <SpeedDial
                                            ariaLabel="SpeedDial basic example"
                                            direction="left"
                                            sx={{
                                                top: 5,
                                                right: 20,
                                                position: "absolute"
                                            }}
                                            icon={<SpeedDialIcon />}
                                            FabProps={{ size: "medium" }}
                                        >
                                            {actions.map((action) => (
                                                <SpeedDialAction
                                                    key={action.name}
                                                    icon={action.icon}
                                                    tooltipTitle={action.name}
                                                    onClick={action.onClick}
                                                />
                                            ))}
                                        </SpeedDial>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="mt-3">
                                        <Tabela
                                            coluna={colunas}
                                            dados={dados}
                                            itemsPerPage={10}
                                            onVisuClick={visualizarUsuario}
                                            onEditClick={editarUsuario}
                                            onDeleteClick={excluirUsuario}
                                            id="codigo"
                                            permissao_editar={permissao_editar}
                                            permissao_visualizar={permissao_visualizar}
                                            permissao_excluir={permissao_excluir}
                                        />
                                    </div>
                                </Row>
                                {/*
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-md-4 text-center mb-2">
                                        <Button variant="primary" className="btn-block"><FaFilePdf />
                                            Exportar para PDF</Button>
                                    </div>
                                    <div className="col-md-4 text-center mb-2">
                                        <Button variant="primary" className="btn-block"><FaFileExcel />
                                            Exportar para EXCEL</Button>
                                    </div>
                                    </div> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>


            <div className="container-fluid mt-2 printyes" hidden>
                <ContainerTitulo titulo="Pedido Status" />
                <img
                    src={`data:image/png;base64,${imagemFilial}`}
                    alt="user"
                    style={{
                        width: "400px",
                        height: "200px",
                        display: "block",
                        margin: "auto"
                    }}
                />
                <Card>
                    <Card.Body>
                        <Box>
                            <div className="table-responsive">
                                <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
                                    <thead className="table-cabecalho">
                                        <tr>
                                            <th>Avatar</th>
                                            <th>Nome</th>
                                            <th>Email</th>
                                            <th>Data de Cadastro</th>
                                            <th>Usuario de cadastro</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {dados.map(function (usuario: any) {
                                            return (<>
                                                <tr>
                                                    <th><Avatar
                                                        src={`data:image/png;base64,${usuario.avatar}`}
                                                        alt="Avatar"
                                                    /></th>
                                                    <th>{usuario.nome_usuario || "---"}</th>
                                                    <th>{usuario.email || "---"}</th>
                                                    <th>{usuario.data_cadastro || "---"}</th>
                                                    <th>{usuario.usuario_cadastro || "---"}</th>
                                                </tr>
                                            </>)
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </Box>
                    </Card.Body>
                </Card>
            </div>

            <ModalLoading show={mostrarModalCarregando} />
        </>
    )
}

export default UsuariosFilial