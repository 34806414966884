import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getSessionData } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';
import ModalDivergencia from '../conferenciaEntrada/components/ModalDivergencia';

//Interface para receber as informações por parametros
interface ModalBotoes {
    isOpen: boolean;
    fecharModal: () => void;
    processo: any;
    OS?: any;
    tipoConferencia: string;
    tipoOperacao?: string;
    permiteDivergencia?: boolean;
}

//Criação do componente
const ModalBotoes: React.FC<ModalBotoes> = ({
    isOpen,
    fecharModal,
    processo,
    OS,
    tipoConferencia,
    tipoOperacao = "",
    permiteDivergencia = false
}) => {

    const navigate = useNavigate()

    const [mostraBtnLerVol, setMostraBtnLerVol] = useState(true)
    const [mostraBtnLerGtin, setMostraBtnLerGtin] = useState(true)
    const [mostraBtnImpressao, setMostraBtnImpressao] = useState(true)
    const [mostraBtnDivergencia, setMostraBtnDivergencia] = useState(true)
    const [mostraModalAprovaDivergencia, setMostraModalAprovaDivergencia] = useState<boolean>(false)
    const [viewBtn, setViewBtn] = useState<boolean>()

    const token = getSessionData("MultfilialWebToken")!
    const idFilial = getSessionData("DadosIdfilial")!
    const usuarioLogado = getSessionData("NomeUsuario")!

    const idPerfil = getSessionData("id_perfil")

    function verificaViewBotoes() {
        if (OS == 0 || OS == null || OS == undefined) {
            return;
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/botoes/${OS}/?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data[0].rg_volume == true && resposta.data[0].rg_volume_por_os == false) {
                setMostraBtnLerVol(false)
            }

            if (resposta.data[0].gtin_saida == true && resposta.data[0].gtin_saida_por_os == false) {
                setMostraBtnLerGtin(false)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.log(erro)
            }
        })
    }

    function consultaViewBtnDivergencia() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/infos/divergencia/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setMostraBtnDivergencia(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setMostraBtnDivergencia(true)
            }
        })
    }

    return (
        <>
            {/* Modal com a finalidade de apresentar os botões de navegação para o usuário */}
            <Modal
                className='mt-3'
                // size='md'
                show={isOpen}
                onShow={() => {
                    if (permiteDivergencia == false) {
                        setMostraBtnDivergencia(true)
                    } else {
                        consultaViewBtnDivergencia()
                    }

                    verificaViewBotoes()
                    if (getSessionData("ProcessoEntrada")) {
                        setMostraBtnImpressao(false)
                    } else {
                        setMostraBtnImpressao(true)
                    }

                    if (tipoOperacao == 'ARMAZENAGEM' || tipoOperacao == 'DEVOLUÇÃO') {
                        setMostraBtnImpressao(false)
                    } else {
                        setMostraBtnImpressao(true)
                    }
                }}
            >
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title>{"Ações"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    {/* <div className='row align-items-end'> */}
                    <Row>
                        <div className="">
                            {/* <button
                                type="button"
                                className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                hidden={false}
                                onClick={() => navigate(`/conferencia/pesagemUA/${OS}`)}
                            >
                                Pesagem UA TESTE
                            </button> */}
                            <button
                                type="button"
                                className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                hidden={mostraBtnLerGtin}
                                onClick={() => navigate(`/conferencia/lerGtin/${OS}/${processo}`)}
                            >
                                Ler Gtin
                            </button>
                            <button
                                type="button"
                                className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                hidden={mostraBtnLerVol}
                                onClick={() => navigate(`/conferencia/lerVolumes/${OS}/${processo}`)}
                            >
                                Ler Volumes
                            </button>
                            <button
                                type="button"
                                className="mt-2 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                onClick={() => {
                                    console.log(processo)
                                    navigate(`/conferencia/saida/equipamentos/${processo}`)
                                }}
                            >
                                Equipamentos
                            </button>
                            <button
                                type="button"
                                className="mt-2 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                onClick={() => navigate(`/conferencia/checkList/${tipoConferencia}`)}
                            >
                                Check-List
                            </button>

                            <button
                                type="button"
                                className="mt-2 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                hidden={mostraBtnImpressao}
                                // hidden={viewBtn}
                                onClick={() => navigate(`/conferencia/entrada/gerarImpressao/${processo}`)}
                            >
                                Gerar Impressão
                            </button>

                            <button
                                type="button"
                                className="mt-2 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                                hidden={mostraBtnDivergencia}
                                onClick={() => {
                                    setMostraModalAprovaDivergencia(true)
                                    fecharModal()
                                }}
                            >
                                Aprovar Divergência
                            </button>
                        </div>
                    </Row>
                    {/* </div> */}
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <button
                        type="button"
                        className="btn btn-outline-secondary btn-sm"
                        onClick={fecharModal}
                    >
                        Voltar
                    </button>
                </Modal.Footer>
            </Modal>

            <ModalDivergencia
                isOpen={mostraModalAprovaDivergencia}
                fecharModal={() => { setMostraModalAprovaDivergencia(false) }}
                processo={processo}
                idFilial={idFilial}
                token={token}
                usuarioLogado={usuarioLogado}
                permissaoDivergencia={permiteDivergencia}
            />
        </>

    )

}

export default ModalBotoes

