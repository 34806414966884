import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Tabela from '../../../../components/Formularios/Table';
import { ITabela } from '../../../../components/Formularios/Table/TabelaInterface';
import axios from 'axios';
import ModalLoading from '../../../../components/Formularios/Modal/ModalLoading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//Interface para receber as informações nos parametros 
interface ModalTabelaOSProps {
    isOpen: boolean;
    fecharModal: () => void;
    OS: any;
    idFilial: any;
    token: any;
}

//Criação do componente
const ModalTabelaDeOS: React.FC<ModalTabelaOSProps> = ({
    isOpen,
    fecharModal,
    OS,
    idFilial,
    token
}) => {

    //Variáveis de estado para uso na aplicação
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [mostralModalLoading, setMostraModalLoading] = useState(false)
    const [dados, setDados] = useState([])

    //Funções de ações para tabela
    const editarUsuario = () => { };
    const excluirUsuario = () => { };
    const visualizarUsuario = () => { }

    //Navegar entre telas
    const navigate = useNavigate()

    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "O.S.", acesso: "OS" },
        { titulo: "Cliente", acesso: "Fantasia" },
        { titulo: "Rota", acesso: "ROTA" },
        { titulo: "Pedido Cliente", acesso: "PEDIDO_CLIENTE" },
        { titulo: "Qtd. de manuseio", acesso: "QTD_MANUSEIO" },
        { titulo: "PB", acesso: "PB" },
        { titulo: "PL", acesso: "PL" },
        { titulo: "Data de carga prevista", acesso: "DATA_CARGA_PREVISTO" }
    ];

    //Função para consultar processo completo para preencher os dados da tabela
    function consultaProcessoCompleto(OS: any, idFilial: any) {
        if (OS == undefined) {
            return;
        }
        setMostraModalLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/processo/completo/${OS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data)
            setMostraModalLoading(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data)
                setMostraModalLoading(false)
            }
        })
    }

    //useEffect para rodar a funcao com o modal for aberto
    // useEffect(() => {
    //     consultaProcessoCompleto(OS, idFilial)
    // }, [isOpen == true])

    return (
        <>
            {/* Controle de visualização do modal */}

            <Modal 
            className='p-0' 
            show={isOpen} 
            size='lg'
            onShow={() => consultaProcessoCompleto(OS, idFilial)}
            >
                {/* Topo do modal com título do objetivo do mesmo*/}
                <Modal.Header className='bg-white'>
                    <Modal.Title>{"OS detalhada"}</Modal.Title>
                </Modal.Header>
                {/* Corpo do modal com a tabela completa */}
                <Modal.Body className='bg-white'>
                    <Tabela
                        coluna={colunas}
                        dados={dados}
                        itemsPerPage={10}
                        onVisuClick={visualizarUsuario}
                        onEditClick={editarUsuario}
                        onDeleteClick={excluirUsuario}
                        id="codigo"
                        permissao_editar={permissao_editar}
                        permissao_visualizar={permissao_visualizar}
                        permissao_excluir={permissao_excluir}
                    />
                </Modal.Body>
                {/* Parte de baixo do modal com os botões */}
                <Modal.Footer className='bg-white'>
                    <button
                        type="button"
                        className="btn btn-outline-secondary btn-sm"
                        onClick={fecharModal}
                    >
                        Voltar
                    </button>
                </Modal.Footer>
            </Modal>

            <ModalLoading show={mostralModalLoading} />
        </>
    )
}

export default ModalTabelaDeOS
