import { useParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalCarregando from "../../../components/ModalCarregando/ModalCarregando";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao";
import { BotaoCustomizado } from "../../../components/ButtonCustomizado";
import { mdiKeyboardReturn, mdiCheckCircle } from '@mdi/js';
import InputSelect from "../../../components/inputSelect/inputSelect";
import CheckBox from "../../../components/CheckBox/checkBox";

const PerfilPermissaoForm = (): JSX.Element => {


	const params = useParams()
	const navigate = useNavigate()
	const token = sessionStorage.getItem("MultfilialWebToken")
	const id_filial = sessionStorage.getItem("DadosIdfilial")!
	const id_perfil = sessionStorage.getItem("id_perfil")!
	// const nivelPerfil = sessionStorage.getItem("NivelPerfil")!
	const usuarioCadastro = sessionStorage.getItem("NomeUsuario")!

	const [showModalCarregando, setShowModalCarregando] = useState(false)

	const [ListaNiveis, setListaNiveis] = useState([
		{
			nivel: "0"
		},
		{
			nivel: "1"
		},
		{
			nivel: "2"
		},
		{
			nivel: "3"
		},
		{
			nivel: "4"
		},
		{
			nivel: "5"
		}
	])


	const [permissoes, setPermissoes] = useState({
		id_filial: id_filial,
		nomePerfil: "",
		nivel: "",
		situacao: true,
		isMaster: false,
		usuarioCadastro: usuarioCadastro,

		visualizarUsuario: false,
		criarUsuario: false,
		editarUsuario: false,
		excluirUsuario: false,

		visualizarPerfilPermissao: false,
		criarPerfilPermissao: false,
		editarPerfilPermissao: false,
		excluirPerfilPermissao: false,

		visualizarUsuariosFilial: false,

		visualizarAtividadesUsuario: false,

		visualizarCodSistema: false,
		criarCodSistema: false,
		editarCodSistema: false,
		excluirCodSistema: false,

		visualizarNfeEntradasArmz: false,
		importarNfeEntradasArmz: false,
		excluirNfeEntradasArmz: false
	})

	const [modalConfirmacao, setModalConfirmacao] = useState(false)

	function AtualizarPermissao(permissao: string, valor: any) {

		setPermissoes((permissoesAtuais) => ({
			...permissoesAtuais,
			[permissao]: valor
		}))
	}


	function CriarPerfil(id_filial: string) {

		setShowModalCarregando(true)
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/perfil/novo/${id_filial}`, permissoes, {
			headers: {
				Authorization: token
			}
		}).then(function (resposta) {

			setShowModalCarregando(false)
			setModalConfirmacao(false)
			toast.success(resposta.data.message, {
				autoClose: 900
			})

			setTimeout(() => {
				navigate(-1)
			}, 1800);
		}).catch(function (erro) {

			setShowModalCarregando(false)
			setModalConfirmacao(false)
			toast.error(erro.response.data.message || erro.message, {
				autoClose: 900
			})
		})
	}

	function CarregarPerfil(id_perfil: string, id_filial: string) {

		setShowModalCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/perfil/criado/${id_filial}/${id_perfil}`, {
			headers: {
				Authorization: token
			}
		}).then(function (resposta) {
			console.log(resposta.data)
			const perfil = resposta.data.perfilPermissao

			setPermissoes((permissoesAtuais) => ({
				...permissoesAtuais,
				nomePerfil: perfil.nome_perfil,
				nivel: perfil.nivel,
				situacao: perfil.situacao == 1 ? true : false,
				isMaster: perfil.master == 1 ? true : false,
				visualizarUsuario: perfil.visualizarUsuario == 1 ? true : false,
				criarUsuario: perfil.criarUsuario == 1 ? true : false,
				editarUsuario: perfil.editarUsuario == 1 ? true : false,
				excluirUsuario: perfil.excluirUsuario == 1 ? true : false,
				visualizarPerfilPermissao: perfil.visualizarPerfilPermissao == 1 ? true : false,
				criarPerfilPermissao: perfil.criarPerfilPermissao == 1 ? true : false,
				editarPerfilPermissao: perfil.editarPerfilPermissao == 1 ? true : false,
				excluirPerfilPermissao: perfil.excluirPerfilPermissao == 1 ? true : false,
				visualizarUsuariosFilial: perfil.visualizarUsuariosFilial == 1 ? true : false,
				visualizarAtividadesUsuario: perfil.visualizarAtividadesUsuario == 1 ? true : false,
				visualizarCodSistema: perfil.visualizarCodSistema == 1 ? true : false,
				criarCodSistema: perfil.criarCodSistema == 1 ? true : false,
				editarCodSistema: perfil.editarCodSistema == 1 ? true : false,
				excluirCodSistema: perfil.excluirCodSistema == 1 ? true : false,
				visualizarNfeEntradasArmz: perfil.visualizarNfeEntradasArmz == 1 ? true : false,
				importarNfeEntradasArmz: perfil.importarNfeEntradasArmz == 1 ? true : false,
				excluirNfeEntradasArmz: perfil.excluirNfeEntradasArmz == 1 ? true : false
			}))

			setShowModalCarregando(false)
		}).catch(function (erro) {

			setShowModalCarregando(false)
			toast.error(erro.response.data.message || erro.message, {
				autoClose: 900
			})
		})
	}


	function AtualizarPerfil(id_perfil: string, id_filial: string) {

		setShowModalCarregando(true)
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/perfil/${id_filial}/${id_perfil}`, permissoes, {
			headers: {
				Authorization: token
			}
		}).then(function (resposta) {

			setShowModalCarregando(false)
			toast.success(resposta.data.message, {
				autoClose: 900
			})

			setTimeout(() => {
				navigate(-1)
			}, 1800);
		}).catch(function (erro) {


			setShowModalCarregando(false)
			toast.error(erro.response.data.message || erro.message, {
				autoClose: 900
			})
		})
	}

	useEffect(function () {

		const NovaListaNiveis = ListaNiveis.filter(function (obj: any) {

			return obj.nivel >= parseInt(nivelPerfil)
		})

		setListaNiveis(NovaListaNiveis)

		if (params.id != "novo" && params.acao != "criar") {

			CarregarPerfil(params.id!, params.id_filial!)
		}
	}, [])


	//#region Novo desenvolvimento

	//#region -> Controle de acesso
	const [nomePerfil, setNomePerfil] = useState<string>("")
	const [nivelPerfil, setNivelPerfil] = useState<string>("")
	const [ativo, setAtivo] = useState<boolean>()

	//Controle de permissão usuário
	const [visualizarUsuario, setVisualizarUsuario] = useState<boolean>(false)
	const [criarUsuario, setCriarUsuario] = useState<boolean>(false)
	const [editarUsuario, setEditarUsuario] = useState<boolean>(false)
	const [excluirUsuario, setExcluirUsuario] = useState<boolean>(false)
	const [acessoTotalUsuario, setAcessoTotalUsuario] = useState<boolean>(false)

	//Controle de perfil permissão
	const [visualizarPerfilPermissao, setVisualizarPerfilPermissao] = useState(false);
	const [criarPerfilPermissao, setCriarPerfilPermissao] = useState(false);
	const [editarPerfilPermissao, setEditarPerfilPermissao] = useState(false);
	const [excluirPerfilPermissao, setExcluirPerfilPermissao] = useState(false);
	const [acessoTotalPerfilPermissao, setAcessoTotalPerfilPermissao] = useState(false);

	//Controle de permissão usuarios/filial
	const [visualizarUsuariosFilial, setVisualizarUsuariosFilial] = useState<boolean>(false)

	//Contorle de permissão painel de monitoramento
	const [visualizarPainel, setVisualizarPainel] = useState<boolean>(false)

	//#region -> Controles de usuário
	const controlaVisualizarUsuario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(true)
		} else {
			setVisualizarUsuario(false)
			setCriarUsuario(false)
			setEditarUsuario(false)
			setExcluirUsuario(false)
			setAcessoTotalUsuario(false)
		}
	}

	const controlaCriarUsuario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(true)
			setCriarUsuario(true)
		} else {
			setCriarUsuario(false)
		}
	}

	const controlaEditarUsuario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(true)
			setEditarUsuario(true)
		} else {
			setEditarUsuario(false)
		}
	}

	const controlaExcluirUsuario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(true)
			setExcluirUsuario(true)
		} else {
			setExcluirUsuario(false)
		}
	}

	const controlaAcessoTotalUsuario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(true)
			setCriarUsuario(true)
			setEditarUsuario(true)
			setExcluirUsuario(true)
			setAcessoTotalUsuario(true)
		} else {
			setVisualizarUsuario(false)
			setCriarUsuario(false)
			setEditarUsuario(false)
			setExcluirUsuario(false)
			setAcessoTotalUsuario(false)
		}
	}

	//#region -> Controle de perfil e permissão
	const controlePerfilPermissaoVisualizar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(true)
		} else {
			setVisualizarPerfilPermissao(false)
			setCriarPerfilPermissao(false)
			setEditarPerfilPermissao(false)
			setExcluirPerfilPermissao(false)
			setAcessoTotalPerfilPermissao(false)
		}
	}

	const controlePerfilPermissaoCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(situacao);
			setCriarPerfilPermissao(situacao);
		} else {
			setCriarPerfilPermissao(situacao);
		}
	};
	const controlePerfilPermissaoEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(situacao);
			setEditarPerfilPermissao(situacao);
		} else {
			setEditarPerfilPermissao(situacao);
		}
	};

	const controlePerfilPermissaoExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(situacao);
			setExcluirPerfilPermissao(situacao);
		} else {
			setExcluirPerfilPermissao(situacao);
		}
	};

	const controleAcessoTotalPerfilPermissao = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(true);
			setCriarPerfilPermissao(true);
			setEditarPerfilPermissao(true);
			setExcluirPerfilPermissao(true);
			setAcessoTotalPerfilPermissao(true);
		} else {
			setVisualizarPerfilPermissao(false);
			setCriarPerfilPermissao(false);
			setEditarPerfilPermissao(false);
			setExcluirPerfilPermissao(false);
			setAcessoTotalPerfilPermissao(false);
		}
	};

	//#region Cadastro Administrativo
	const [visualizarFilial, setVisualizarFilial] = useState<boolean>(false)
	const [criarFilial, setCriarFilial] = useState<boolean>(false)
	const [editarFilial, setEditarFilial] = useState<boolean>(false)
	const [excluirFilial, setExcluirFilial] = useState<boolean>(false)
	const [acessoTotalFilial, setAcessoTotalFilial] = useState<boolean>(false)

	const controlaAcessoTotalFilial = (situacao: boolean) => {
		if (situacao) {
			setVisualizarFilial(true)
			setCriarFilial(true)
			setEditarFilial(true)
			setExcluirFilial(true)
			setAcessoTotalFilial(true)
		} else {
			setVisualizarFilial(false)
			setCriarFilial(false)
			setEditarFilial(false)
			setExcluirFilial(false)
			setAcessoTotalFilial(false)
		}
	}

	const controlaVisualizarFilial = (situacao: boolean) => {
		if (situacao) {
			setVisualizarFilial(true)
		} else {
			setVisualizarFilial(false)
			setCriarFilial(false)
			setEditarFilial(false)
			setExcluirFilial(false)
			setAcessoTotalFilial(false)
		}
	}

	const controlaCriarFilial = (situacao: boolean) => {
		if (situacao) {
			setVisualizarFilial(situacao);
			setCriarFilial(situacao);
		} else {
			setCriarFilial(situacao);
		}
	};

	const controlaEditarFilial = (situacao: boolean) => {
		if (situacao) {
			setVisualizarFilial(situacao);
			setEditarFilial(situacao);
		} else {
			setEditarFilial(situacao);
		}
	};

	const controlaExcluirFilial = (situacao: boolean) => {
		if (situacao) {
			setVisualizarFilial(situacao);
			setExcluirFilial(situacao);
		} else {
			setExcluirFilial(situacao);
		}
	};


	//#region Functions:
	const dadosEnvio = {
		nomePerfil,
		nivelPerfil,
		ativo,

		visualizarUsuario,
		criarUsuario,
		editarUsuario,
		excluirUsuario,
		visualizarPerfilPermissao,
		criarPerfilPermissao,
		editarPerfilPermissao,
		excluirPerfilPermissao,
		visualizarUsuariosFilial,
		visualizarPainel,

		visualizarFilial,
		criarFilial,
		editarFilial,
		excluirFilial
	}

	function criarPerfil() {
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/perfil`, {
			dadosEnvio
		}, {
			headers: {
				Authorization: token
			}
		}).then(function (resposta) {
			console.log(resposta)
		}).catch(function (erro) {
			const status = erro.response.status
			if (status === 403 || status === 401) {
				toast.error("Acesso negado, faça seu login novamente")
				navigate("/login")
			} else {
				toast.error(erro.response.data.message)
			}
		})
	}

	return (
		<>
			<div className='main'>
				<div className='tituloDaPagina mt-2 ms-1'>
					<h4>{params.acao == "criar" ? "Criar novo Perfil" : params.acao == "editar" ? "Editar Perfil" : "Visualizar Perfil"}</h4>
				</div>
				<div className="card mt-2 pb-3">
					<div className="card-body">
						<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
									Perfil
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="pills-profile-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-profile"
									type="button"
									role="tab"
									aria-controls="pills-profile"
									aria-selected="false"
								>
									Permissões Básicas
								</button>
							</li>
						</ul>

						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane fade show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
								tabIndex={0}
							>
								<div className="container p-0 m-0">
									<div className="row">
										<div className="col-sm col-md-6 col-lg-5">
											<div className="form-floating">
												<input
													value={nomePerfil}
													onChange={(e) => setNomePerfil(e.target.value)}
													required
													type="text"
													name="nomePerfil"
													className="form-control bordasInferiorInput text-black text-capitalize"
													placeholder=" "
												/>
												<label>Nome do Perfil</label>
											</div>
										</div>
										<div className="col-sm col-md-6 col-lg-3 form-floating">
											<select
												autoFocus
												className="form-select bordasInferiorInput text-black text-capitalize"
												onChange={(event) => {
													setNivelPerfil(event.target.value)
												}}
												value={nivelPerfil}
											>
												<option>Selecione</option>
												<option>0</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
												<option>5</option>
											</select>
											<label className="form-label">Nível</label>
										</div>
										<div className="col-sm col-md-6 col-lg-2 form-check form-switch">
											<input
												className="form-check-input me-2"
												type="checkbox"
												checked={ativo}
												id="ativo"
												onChange={(e) => {
													setAtivo(e.target.checked)
												}}
												style={{
													width: '55px'
												}}
											/>
											<label className="form-check-label mt-1 col" htmlFor="ativo">
												Ativo
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
								<div className="accordion bg-primary text-white">
									<div className="accordion-item">

										<h2 className="accordion-header">
											<button
												className="accordion-button bg-primary  text-white"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												Administrativo
											</button>
										</h2>

										<div id="collapseOne" className="accordion-collapse collapse bg-white text-primary">
											<div className="accordion-body">
												<div className="accordion  bg-primary text-white">
													<div className="accordion-item">

														<h2 className="accordion-header">
															<button
																className="accordion-button  bg-primary text-white"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#collapse2"
																aria-expanded="true"
																aria-controls="collapse2"
															>
																Controle de Acesso
															</button>
														</h2>

														<div id="collapse2" className="accordion-collapse collapse bg-white text-black">
															<div className="accordion-body">
																<div className="row">
																	<div className="col-sm col-md col-lg-2 text-center">
																		<p className="mb-0 pb-0">
																			<b>Menu</b>
																		</p>
																		<p>Usuários</p>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Visualizar</p>
																		<CheckBox
																			checked={visualizarUsuario}
																			onChange={controlaVisualizarUsuario}
																			disabled={params.acao || ''}
																			label=""
																			name="visualizarUsuario"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Criar</p>
																		<CheckBox
																			checked={criarUsuario}
																			onChange={controlaCriarUsuario}
																			disabled={params.acao || ''}
																			label=""
																			name="criarUsuario"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Editar</p>
																		<CheckBox
																			checked={editarUsuario}
																			onChange={controlaEditarUsuario}
																			disabled={params.acao || ''}
																			label=""
																			name="editarUsuario"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Excluir</p>
																		<CheckBox
																			checked={excluirUsuario}
																			onChange={controlaExcluirUsuario}
																			disabled={params.acao || ''}
																			label=""
																			name="excluirUsuario"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">
																			<strong>Acesso Total</strong>
																		</p>
																		<CheckBox
																			checked={acessoTotalUsuario}
																			onChange={controlaAcessoTotalUsuario}
																			disabled={params.acao || ''}
																			label=""
																			name="acessoTotalUsuario"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																</div>

																<div className="row border-1 border-top border-primary">
																	<div className="col-sm col-md col-lg-2 text-center">
																		<p className="mb-0 pb-0">
																			<b>
																				<br />
																			</b>
																		</p>
																		<p>Perfil/Permissão</p>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Visualizar</p>
																		<CheckBox
																			checked={visualizarPerfilPermissao}
																			onChange={controlePerfilPermissaoVisualizar}
																			disabled={params.acao || ''}
																			label=""
																			name="visualizarPerfilPermissao"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Criar</p>
																		<CheckBox
																			checked={criarPerfilPermissao}
																			onChange={controlePerfilPermissaoCriar}
																			disabled={params.acao || ''}
																			label=""
																			name="criarPerfilPermissao"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Editar</p>
																		<CheckBox
																			checked={editarPerfilPermissao}
																			onChange={controlePerfilPermissaoEditar}
																			disabled={params.acao || ''}
																			label=""
																			name="editarPerfilPermissao"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Excluir</p>
																		<CheckBox
																			checked={excluirPerfilPermissao}
																			onChange={controlePerfilPermissaoExcluir}
																			disabled={params.acao || ''}
																			label=""
																			name="excluirPerfilPermissao"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">
																			<strong>Acesso Total</strong>
																		</p>
																		<CheckBox
																			checked={acessoTotalPerfilPermissao}
																			onChange={controleAcessoTotalPerfilPermissao}
																			disabled={params.acao || ''}
																			label=""
																			name="acessoTotalPerfilPermissao"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																</div>

																<div className="row border-1 border-top border-primary">
																	<div className="col-sm col-md col-lg-2 text-center">
																		<p className="mb-0 pb-0">
																			<b>
																				<br />
																			</b>
																		</p>
																		<p>Usuários Filial</p>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Visualizar</p>
																		<CheckBox
																			checked={visualizarUsuariosFilial}
																			onChange={setVisualizarUsuariosFilial}
																			disabled={params.acao || ''}
																			label=""
																			name="visualizarPerfilPermissao"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																</div>

																<div className="row border-1 border-top border-primary">
																	<div className="col-sm col-md col-lg-2 text-center">
																		<p className="mb-0 pb-0">
																			<b>
																				<br />
																			</b>
																		</p>
																		<p>Painel monitoramento</p>
																	</div>
																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Visualizar</p>
																		<CheckBox
																			checked={visualizarPainel}
																			onChange={setVisualizarPainel}
																			disabled={params.acao || ''}
																			label=""
																			name="visualizarPainel"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																</div>

															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="accordion-body">
												<div className="accordion  bg-primary text-white">
													<div className="accordion-item">
														<h2 className="accordion-header">
															<button
																className="accordion-button  bg-primary text-white"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#collapse4"
																aria-expanded="true"
																aria-controls="collapse4"
															>
																Cadastro Administrativo
															</button>
														</h2>
														<div
															id="collapse4"
															className="accordion-collapse collapse bg-white text-black"
															data-bs-parent="#CadastroAdministrativo"
														>
															<div className="accordion-body">
																<div className="row">
																	<div className="col-sm col-md col-lg-2 text-center">
																		<p className="mb-0 pb-0">
																			<b>Menu</b>
																		</p>
																		<p>Cadastro de Filial</p>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Visualizar</p>
																		<CheckBox
																			checked={visualizarFilial}
																			onChange={controlaVisualizarFilial}
																			disabled={params.acao || ''}
																			label=""
																			name="visualizarFilial"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Criar</p>
																		<CheckBox
																			checked={criarFilial}
																			onChange={controlaCriarFilial}
																			disabled={params.acao || ''}
																			label=""
																			name="criarFilial"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Editar</p>
																		<CheckBox
																			checked={editarFilial}
																			onChange={controlaEditarFilial}
																			disabled={params.acao || ''}
																			label=""
																			name="editarFilial"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Excluir</p>
																		<CheckBox
																			checked={excluirFilial}
																			onChange={controlaExcluirFilial}
																			disabled={params.acao || ''}
																			label=""
																			name="excluirFilial"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>

																	<div className="col-sm col-md col-lg-2 text-center justify-content-center form-switch">
																		<p className="mb-0 pb-0">Acesso Total</p>
																		<CheckBox
																			checked={acessoTotalFilial}
																			onChange={controlaAcessoTotalFilial}
																			disabled={params.acao || ''}
																			label=""
																			name="acessoTotalFilial"
																			className="form-check-input w-50"
																			classNameDiv="form-check form-switch justify-content-center d-flex"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<ModalCarregando mensagem="Carregando..." mostrar={showModalCarregando} />
			<ModalConfirmacao
				msgPrincipal="Deseja criar esse perfil para todas as filiais ou apenas para filial Logada ?"
				msgAcao1="Todas Filiais"
				msgAcao2="Filial Logada"
				Acao1={function () {
					CriarPerfil("0")
				}}
				Acao2={function () {
					CriarPerfil(id_filial)
				}}
				mostrar={modalConfirmacao}
			/>
		</>
	)
};

export { PerfilPermissaoForm };
