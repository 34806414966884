import { Modal, Row } from "react-bootstrap"

interface ModalConfirmProps {
    isOpen: boolean;
    fecharModal: () => void;
    mensagem: string;
    onConfirm: (params: any) => void;
    params?: any;
    mostrarMoldal?: (mostrar: boolean) => void
}
// -> O parametro mostrar modal serve para quando este componente for implementado em outro modal

const ModalConfirm: React.FC<ModalConfirmProps> = ({
    isOpen,
    fecharModal,
    mensagem,
    onConfirm,
    params,
    mostrarMoldal
}) => {
    return (
        <>
            <Modal className='mt-5'
                show={isOpen}>
                <Modal.Header className="bg-white " style={{ justifyContent: 'center' }}>
                    <Modal.Title className="text-center">{mensagem}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="d-flex justify-content-between">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded"
                                onClick={fecharModal}
                            >
                                Cancelar
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded"
                                onClick={() => {
                                    onConfirm(params)
                                    fecharModal()

                                    if (mostrarMoldal) {
                                        mostrarMoldal(false)
                                    }
                                }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalConfirm