import { useState } from "react"
import { Card, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import ModalGtinCargaHistorico from "./components/gtinCargaHistorico"
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import { getSessionData } from "../../../utils/storageUtils";

function MenuMonitoramento() {

    const navigate = useNavigate()

    const [mostraModalGtinHistorico, setMostraModalGtinHistorico] = useState<boolean>(false)
    const token = getSessionData("MultfilialWebToken") ?? "";
    const idFilial = getSessionData("DadosIdfilial") ?? ""

    function menuAdm() {
        navigate("/menu/menuAdministrativo")
    }



    return (
        <>
            <Card className="mt-4">
                {/* <Row>
                    <div className="mb-2">
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            direction="left"
                            sx={{
                                top: 5,
                                right: 20,
                                position: "absolute"
                            }}
                            icon={<SpeedDialIcon />}
                            FabProps={{ size: "medium" }}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={action.onClick}
                                />
                            ))}
                        </SpeedDial>
                    </div>
                </Row> */}

                <div className="container mt-5 mx-auto text-center mb-3 mt-md-4">
                    <button
                        type="button"
                        className="btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                        onClick={() => window.open(`/monitoramento/dados/gtin/${token}/${idFilial}`, '_blank')}
                    >
                        Leitura Gtin Carga - Em Andamento
                    </button>
                    <button
                        type="button"
                        className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                        onClick={() => setMostraModalGtinHistorico(true)}
                    >
                        Leitura Gtin Carga - Histórico
                    </button>
                    <button
                        type="button"
                        className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                        onClick={() => navigate("/menu/produtividadeGtin/admin")}
                    >
                        Produtividade Leitura Gtin Carga
                    </button>

                </div>
            </Card>
        </>
    )
}

export default MenuMonitoramento