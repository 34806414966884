import { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { FaSave } from "react-icons/fa";


interface ModalManualEVariadoProps {
    isOpen: boolean;
    fecharModal: () => void;
    qtdeTransferir: any;
    checkBarras: (barras: any) => void;
    deleteRegTabTemporaria: () => void;
    setBarra: React.Dispatch<React.SetStateAction<any>>;
    barra: any;
    romaneados: number;
}

const ModalManualEVariado: React.FC<ModalManualEVariadoProps> = ({
    isOpen,
    fecharModal,
    qtdeTransferir,
    checkBarras,
    deleteRegTabTemporaria,
    setBarra,
    barra,
    romaneados
}) => {

    const codigoRef = useRef(null)

    //Funçao para mudança de foco
    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    useEffect(() => {
        if (romaneados == qtdeTransferir) {
            fecharModal()
        }
    }, [checkBarras])

    return (
        <>
            <Modal className="mt-3" show={isOpen} >
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title >Romanear</Modal.Title>
                </Modal.Header >
                <Modal.Body className="bg-white">

                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                autoFocus
                                value={barra}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setBarra(event.target.value)
                                }}
                                onBlur={() => checkBarras(barra)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        checkBarras(barra)
                                        handleKeyDown(event, codigoRef)
                                    }
                                }}
                                ref={codigoRef}
                            />
                            <label className="form-label">BARRA</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 mt-2">
                            <div className="col form-floating">
                                <input
                                    type="number"
                                    value={qtdeTransferir}
                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                    disabled
                                />
                                <label>VOLUMES A TRANSFERIR </label>
                            </div>
                        </div>

                        <div className="col-4 mt-2">
                            <div className="col form-floating">
                                <input
                                    type="number"
                                    value={romaneados}
                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                    disabled
                                />
                                <label>ROMANEADOS</label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div>
                        <button
                            type="button"
                            className="me-2 btn btn-danger rounded"
                            onClick={deleteRegTabTemporaria}
                        >
                            Cancelar
                        </button>

                        <button
                            type="button"
                            className="btn btn-novo"
                            onClick={() => {
                                fecharModal()
                            }}
                        // onKeyDown={() => }
                        >
                            <span className='me-1'><FaSave size={15} /></span>Salvar
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalManualEVariado