import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Interface para receber as informações nos parametros 
interface ModalNovoEquipamentoProps {
    isOpen: boolean;
    fecharModal: () => void;
    idFilial: any
    token: any
    processo: any
    usuarioLogado: any
    consultaEquipamento: () => void;
}

//Criação do componente
const ModalNovoEquipamento: React.FC<ModalNovoEquipamentoProps> = ({
    isOpen,
    fecharModal,
    idFilial,
    token,
    processo,
    usuarioLogado,
    consultaEquipamento
}) => {

    const navigate = useNavigate()

    //Variáveis de estado para uso na aplicação
    const [equipamentos, setEquipamentos] = useState([])
    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState('');
    const [btnIniciar, setBtnIniciar] = useState(false)
    const [btnConcluir, setBtnConcluir] = useState(true)
    const [qtd, setQtd] = useState<any>()

    const novoEquipRef = useRef(null)
    const qtdRef = useRef(null)
    const btnSalvarRef = useRef(null)

    //Função para buscar equipamentos e preencher dropdown 
    function buscaEquipamentos() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/equipamentos?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setEquipamentos(resposta.data)
        }).catch(function (erro) {
            if (erro.response.status == 406) {
                toast.error(erro.response.data.message)
            }
        })
    }

    //Função para realizar o cadastro de equipamentos
    function cadastraEquipamento() {
        if (equipamentoSelecionado == "Selecione um equipamento" || equipamentoSelecionado == "") {
            toast.error("Selecione um equipamento")
        } else if (qtd == "" || qtd == 0) {
            toast.error("Insiria uma quantidade válida")
        } else {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/cadastra/equipamentos?idFilial=${idFilial}`, { 
                equipamentoSelecionado, 
                qtd, 
                processo,
                usuarioLogado 
            }, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                toast.success("Equipamento cadastrado com sucesso")
                consultaEquipamento()
                fecharModal()
            }).catch(function (erro) {
                const status = erro.response.status
                if (status == 403 || status == 401) {
                    toast.error("Acesso negado, faça seu login novamente")
                    navigate("/login")
                } else {
                    console.log(erro)
                    toast.error("Não foi possível realizar o cadastro de equipamentos")
                }
            })
        }
    }

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    const handleModalShow = () => {
        setEquipamentoSelecionado("Selecione um equipamento")
        setQtd("")
    }

    //useEffect para carregar equipamentos quando a tela for aberta
    useEffect(() => {
        buscaEquipamentos()
    }, [])

    return (
        <>
            {/* Controle de visualização do modal */}
            <Modal className='mt-3' show={isOpen} onShow={handleModalShow}>
                {/* Topo do modal com título do objetivo do mesmo*/}
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title>{"Novo Equipamento"}</Modal.Title>
                </Modal.Header>
                {/* Corpo do modal com os inputs para cadastro de novo equipamento */}
                <Modal.Body className="bg-white">
                    <Row>
                        <div className="col-12">
                            <label htmlFor="selectEquipamento">Equipamento:</label>
                            <select
                                id="selectEquipamentos"
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                value={equipamentoSelecionado}
                                autoFocus
                                onChange={(event) => {
                                    setEquipamentoSelecionado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, qtdRef)}
                            >
                                <option value="">Selecione um equipamento</option>

                                {equipamentos.map((equipamento: any, index: any) => (
                                    <option key={index} value={equipamento.id_equipamento}>{equipamento.descricao}</option>
                                ))}

                            </select>
                        </div>
                        <div className="mb-1 mt-2 form-floating">
                            <input
                                type="text"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                placeholder=''
                                onChange={(event) => {
                                    setQtd(event.target.value)
                                }}
                                ref={qtdRef}
                                onKeyDown={(event) => handleKeyDown(event, btnSalvarRef)}
                            />
                            <label className="form-label">Quantidade</label>
                        </div>
                    </Row>
                </Modal.Body>
                {/* Parte de baixo do modal com os botões */}
                <Modal.Footer className="bg-white">
                    <Row className="d-flex justify-content-between w-100">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded"
                                onClick={fecharModal}
                            >
                                Cancelar
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded"
                                ref={btnSalvarRef}
                                onClick={cadastraEquipamento}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        cadastraEquipamento()
                                    }
                                }}
                            >
                                Salvar
                            </button>
                        </div>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalNovoEquipamento