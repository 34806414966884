import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiPlusCircle } from '@mdi/js';
import { toast } from 'react-toastify';
import { ITabela } from '../../../components/Formularios/Table/TabelaInterface';
import CarregarPermissoes from '../../../hooks/carregaPermissoesUsuario/carregaPermissoes';
import ClickableTable from '../../../components/ClickTable/ClickTable';
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';
import ModalConfirmacao from '../../../components/ModalConfirmacao/ModalConfirmacao';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Row } from 'react-bootstrap';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";

const PerfilPermissoes = (): JSX.Element => {

	const token = sessionStorage.getItem("MultfilialWebToken")
	const id_filial = sessionStorage.getItem("DadosIdfilial")!
	const id_perfil = sessionStorage.getItem("id_perfil")!
	const nivelPerfil = sessionStorage.getItem("NivelPerfil")!


	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);

	const colunas: ITabela[] = [
		{
			titulo: "ID Perfil",
			acesso: "id_perfil"
		},
		{
			titulo: "Perfil",
			acesso: "nome_perfil"
		},
		{
			titulo: "Status",
			acesso: "situacao"
		},
		{
			titulo: "Usuario de Cadastro",
			acesso: "usuario"
		},
		{
			titulo: "Data do Cadastro",
			acesso: "cadastro_formatada"
		}
	]

	const [itensPorPagina, setItensPorPagina] = useState(10)
	const navigate = useNavigate()
	const [ListaDePerfis, setListaDePerfis] = useState([])

	function CarregarListaDePerfis(id_filial: any, id_perfil: any) {
		setShowCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/perfil/inferiores/${id_filial}/${id_perfil}/${nivelPerfil}`, {
			headers: {
				Authorization: token
			}
		}).then(function (resposta) {

			setShowCarregando(false)
			setListaDePerfis(resposta.data.perfis)
		}).catch(function (erro) {
			setShowCarregando(false)
			toast.error(erro.response.data || erro.message)
		})
	}


	function ExcluirPerfil(id_filialPerfil: string, id_perfilPerfil: string) {

		setShowModalConfirmacao(false)
		setShowCarregando(true)
		axios.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/perfil/${id_filialPerfil}/${id_perfilPerfil}`, {
			headers: {
				Authorization: token
			}
		}).then(function (resposta) {

			setShowCarregando(false)
			CarregarListaDePerfis(id_filial, id_perfil)
			toast.success(resposta.data.message, {
				autoClose: 900
			})
		}).catch(function (erro) {

			setShowCarregando(false)
			toast.error(erro.response.data.message || erro.message, {
				autoClose: 900
			})
		})
	}

	const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
	const [dadosExclusao, setDadosExclusao] = useState<any>({})

	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false)
	const [permissaoCriar, setPermissaoCriar] = useState(false)
	const [permissaoEditar, setPermissaoEditar] = useState(false)
	const [permissaoExcluir, setPermissaoExcluir] = useState(false)

	useEffect(function () {

		CarregarPermissoes(id_perfil, id_filial, nivelPerfil).then(function (perfil: any) {

			if (perfil["visualizarPerfilPermissao"] == false) {

				toast.error("Sem permissão para visualizar essa página.")
				navigate("/")
			}
			else {

				setPermissaoVisualizar(perfil["visualizarPerfilPermissao"])
				setPermissaoCriar(perfil["criarPerfilPermissao"])
				setPermissaoEditar(perfil["editarPerfilPermissao"])
				setPermissaoExcluir(perfil["excluirPerfilPermissao"])
			}
		}).catch(function (erro: any) {

			toast.error(erro, {
				autoClose: 900
			})
		})
		CarregarListaDePerfis(id_filial, id_perfil)
	}, [])

	const actions = [
		{
			icon: (
				<AddIcon
					onClick={() => navigate(`/config/segur/cadastroDePermissoes/criar/novo/${id_filial}`)}
				/>
			),
			name: "Incluir",
		}
	];

	return (
		<>
			<div className='main'>
				<div className='tituloDaPagina mt-2 ms-1'>
					<h4>Perfil/Permissões</h4>
				</div>
				<div className="card mt-2 pb-3">
					<div className="card-body">
						<Row className="mb-1">
							<SpeedDial
								ariaLabel="SpeedDial basic example"
								direction="left"
								sx={{
									right: 0,
									position: "absolute",
								}}
								icon={<SpeedDialIcon />}
								FabProps={{ size: "large" }}
							>
								{actions.map((action) => (
									<SpeedDialAction
										key={action.name}
										icon={action.icon}
										tooltipTitle={action.name}
									/>
								))}
							</SpeedDial>
						</Row>
						{/* <div className='container p-0 m-0' hidden={!permissaoCriar}>
							<div className="row">
								<div className='col-sm col-md col-lg-7'>
									<button onClick={function () {
										navigate(`/config/segur/cadastroDePermissoes/criar/novo/${id_filial}`)
									}} className='btn btn-secondary w-25'>
										<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
										Novo Perfil
									</button>
								</div>
							</div>
						</div> */}
						<ClickableTable
							coluna={colunas}
							itemsPerPage={itensPorPagina}
							data={ListaDePerfis}
							onRowClick={function () {

							}}
							acaoEditar={function (dado: any) {
								navigate(`/config/segur/cadastroDePermissoes/editar/${dado.id_perfil}/${dado.id_filial}`)
							}}
							acaoExcluir={function (dado: any) {
								setShowModalConfirmacao(true)
								setDadosExclusao(dado)
							}}
							acaoVisualizar={function (dado: any) {
								navigate(`/config/segur/cadastroDePermissoes/visualizar/${dado.id_perfil}/${dado.id_filial}`)
							}}
							usaAcoes={permissaoVisualizar}
							usaEditar={permissaoEditar}
							usaExcluir={permissaoExcluir}
							usaVisualizar={permissaoVisualizar}
							acessopesquisa='nome_perfil'
							labelpesquisa='Procurar por perfil'
						/>
					</div>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-2 m-auto text-center'>
								<label>Itens por Página</label>
							</div>
							<div className='w-100'></div>
							<div className='col-lg-1 m-auto'>

								<select value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value)
									}}
									className="form-select form-select-sm" aria-label=".form-select-sm example">
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalCarregando
				mensagem='Carregando...'
				mostrar={showCarregando}
			/>
			<ModalConfirmacao
				mostrar={showModalConfirmacao}
				msgPrincipal='Realmente deseja deletar o perfil ?'
				msgAcao1='Cancelar'
				Acao1={function () {
					setShowModalConfirmacao(false)
				}}
				msgAcao2='Confirmar'
				Acao2={function () {
					ExcluirPerfil(dadosExclusao.id_filial, dadosExclusao.id_perfil)
				}}
			/>
		</>
	)
};

export { PerfilPermissoes };
