import { Card, Row } from "react-bootstrap"
import Switch from '@mui/material/Switch';
import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { stat } from "fs";
import { TbListDetails } from "react-icons/tb";
import ModalManualEVariado from "./components/ModalManualEVariado";
import ModalVariadoBalanca from "./components/ModalVariavelBalanca";

function Desfragmentar() {
    //Variáveis de estado
    const navigate = useNavigate()
    const [UAOrigem, setUAOrigem] = useState<any>()
    const [qtdeUA, setQtdeUA] = useState<any>()
    const [codigoOrigem, setCodigoOrigem] = useState<any>()
    const [codigoDestino, setCodigoDestino] = useState<any>()
    const [qtdeTransferir, setQtdeTransferir] = useState<any>()
    const [UADestino, setUADestino] = useState<any>()
    const [validadeOrigem, setValidadeOrigem] = useState<any>()
    const [validadeDestino, setValidadeDestino] = useState<any>()
    const [viewBtnRomanear, setViewBtnRomanear] = useState(true)
    const [tipoPesoUAOrigem, setTipoPesoUAOrigem] = useState<any>()
    const [mostraModalManualEVariado, setMostraModalManualEVariado] = useState(false)
    const [mostraModalVariavelBalanca, setMostraModalVariavelBalanca] = useState(false)
    const [WMS, setWMS] = useState()
    const [pesoBruto, setPesoBruto] = useState<any>()
    const [pesoEmbMultiplicado, setPesoEmbMultiplicado] = useState<any>()
    const [pesoLiquido, setPesoLiquido] = useState<any>()
    const [barra, setBarra] = useState<any>()
    const [romaneados, setRomaneados] = useState<number>(0)
    const [UANovaCheck, setUANovaCheck] = useState<any>(false)
    const [CamposDestinoDesabilitado, setCamposDestinoDesabilitado] = useState<any>(false)

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    //Criandos referencias para mudança de foco
    const UAOrigemRef = useRef<HTMLInputElement>(null);
    const qtdeUARef = useRef(null);
    const codigoOrigemRef = useRef(null);
    const qtdeTransferirRef = useRef(null);
    const UADestinoRef = useRef(null);
    const UANovaRef = useRef(null);
    const codigoDestinoRef = useRef(null);
    const btnDesfragmentarRef = useRef(null);
    const validadeOrigemRef = useRef(null);
    const validadeDestinoRef = useRef(null);

    //Funçao para mudança de foco
    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    const autoFoco = (ref: any) => { ref.current?.focus() }

    function handleUaNovaChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
        setUANovaCheck(checked)
        if (checked) {
            setUADestino("")
            setCodigoDestino("")
            setValidadeDestino("")
            setCamposDestinoDesabilitado(true)
        } else {
            setCamposDestinoDesabilitado(false)
        }
    }


    const handleClickModalManualEVariado = () => {
        if (UAOrigem == "" || UAOrigem == undefined || qtdeTransferir == "" || qtdeTransferir == undefined) {
            toast.info("UA de origem e QTDE a transferir precisam ser preenchidos")
        } else {
            if (tipoPesoUAOrigem != "PADRÃO" && tipoPesoUAOrigem != "VARIADO_BALANÇA" && romaneados == qtdeTransferir) {
                toast.info("Qtde para transferir já romaneada")
            } else {
                if (tipoPesoUAOrigem == "PADRÃO") {
                    return;
                } else if (tipoPesoUAOrigem != "VARIADO_BALANÇA") {
                    setMostraModalManualEVariado(true)
                } else {
                    // setMostraModalManualEVariado(true)
                    setMostraModalVariavelBalanca(true)
                    consultaPesoEmbalagem()
                }
            }

        }
    }

    function consultaUAOrigem(ref: any, UAEnviada: any) {
        if (UAEnviada == "" || UAEnviada == undefined) {
            setQtdeUA("")
            setCodigoOrigem("")
            setValidadeOrigem("")
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/ua/${UAEnviada}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setQtdeUA(resposta.data[0].qtde_disp)
            setCodigoOrigem(resposta.data[0].codigo)
            setWMS(resposta.data[0].id_wms)
            setValidadeOrigem(resposta.data[0].validade)
            ref.current?.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                    setQtdeUA("")
                    setCodigoOrigem("")
                    setValidadeOrigem("")
                    UAOrigemRef.current?.focus()
                }
            }
        })
    }

    function consultaUADestino(ref: any, UAEnviada: any) {
        if (UAEnviada == "" || UAEnviada == undefined) {
            setCodigoDestino("")
            setValidadeDestino("")
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/ua/${UAEnviada}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setCodigoDestino(resposta.data[0].codigo)
            setValidadeDestino(resposta.data[0].validade)
            ref.current?.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                    setCodigoDestino("")
                    setValidadeDestino("")
                    UAOrigemRef.current?.focus()
                }
            }
        })
    }

    async function verificaPesosPreDesfragmentar(): Promise<boolean | { success: boolean; message: string }> {
        if (tipoPesoUAOrigem == "PADRÃO") {
            return true;
        } else if (tipoPesoUAOrigem == "VARIADO_BALANÇA") {
            if (
                pesoBruto != "" &&
                pesoBruto != 0 &&
                pesoBruto != undefined &&
                pesoLiquido != "" &&
                pesoLiquido != 0 &&
                pesoLiquido != undefined
            ) {
                return true;
            } else {
                return { success: false, message: "Insira o peso para prosseguir" };
            }
        } else if (romaneados == qtdeTransferir) {
            return true;
        } else {
            return { success: false, message: "Romaneie todas as barras para prosseguir" };
        }
    }

    async function desfragmentar(ref: any) {
        let menorValidade
        if (validadeDestino < validadeOrigem) {
            menorValidade = validadeDestino
        } else if (validadeOrigem < validadeDestino) {
            menorValidade = validadeOrigem
        } else {
            menorValidade = validadeDestino
        }

        const verificaPeso = await verificaPesosPreDesfragmentar();
        if (typeof verificaPeso !== 'boolean' && verificaPeso.success === false) {
            toast.error(verificaPeso.message);
            return;
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/desfragmentar?idFilial=${idFilial}`, {
            UAOrigem,
            qtdeTransferir,
            tipoPesoUAOrigem,
            pesoBruto,
            pesoLiquido,
            UADestino,
            UANovaCheck,
            validadeOrigem,
            validadeDestino,
            codigoOrigem,
            codigoDestino,
            usuarioLogado,
            menorValidade
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            setUAOrigem("")
            setQtdeUA("")
            setCodigoOrigem("")
            setValidadeOrigem("")
            setQtdeTransferir("")
            setUADestino("")
            setCodigoDestino("")
            setValidadeDestino("")
            verificaRomaneados()
            ref.current?.focus()
        }).catch(function (erro) {
            console.log(erro)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                } else {
                    toast.error(erro.response.data.message)
                }
            }
        })
    }

    function verificaRomaneados() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/romaneados/${UAOrigem}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            console.log(resposta.data)
            setRomaneados(resposta.data)
        }).catch(function (erro) {
            console.log(erro)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                console.log(erro.response.data)
            }

        })
    }

    function verificaTipoPesoPallet() {
        if (UAOrigem == "" || UAOrigem == undefined) {
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/peso/${UAOrigem}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setTipoPesoUAOrigem(resposta.data[0].TIPO_PESO)
            if (resposta.data[0].TIPO_PESO != "PADRÃO") {
                setViewBtnRomanear(false)
            } else {
                setViewBtnRomanear(true)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data)
            }
        })
    }

    function checkBarras(barra: any) {
        if (barra == "" || barra == undefined) {
            return;
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/check/barras/${UAOrigem}/${barra}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setBarra("")
            verificaRomaneados()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                    setBarra("")
                }
            }
        })
    }

    function deleteRegTabTemporaria() {
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/reg/temporaria/${UAOrigem}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            verificaRomaneados()
            setMostraModalManualEVariado(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.log(erro.response.data)
            }
            setMostraModalManualEVariado(false)

        })
    }

    function consultaPesoEmbalagem() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/peso/embalagem/${WMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPesoEmbMultiplicado(resposta.data[0].PESO_EMBAL * qtdeTransferir)
        }).catch(function (erro) {
            console.log(erro.response.data)
        })
    }


    function fecharModal() {
        setMostraModalVariavelBalanca(false)
        setMostraModalManualEVariado(false)
    }

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <div className="mb-1 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                        <label className="form-label" style={{ paddingTop: '5px' }}>
                            <strong>ORIGEM</strong>
                        </label>
                    </div>
                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            autoFocus
                            value={UAOrigem}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onChange={(event) => {
                                setUAOrigem(event.target.value)
                            }}
                            onBlur={(event) => {
                                consultaUAOrigem(qtdeTransferirRef, UAOrigem)
                                verificaTipoPesoPallet()
                                setUAOrigem(event.target.value)
                            }}
                            onKeyDown={(event) => { handleKeyDown(event, qtdeUARef) }}
                            ref={UAOrigemRef}
                        />
                        <label className="form-label">U.A.</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            value={qtdeUA}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onKeyDown={(event) => handleKeyDown(event, codigoOrigemRef)}
                            ref={qtdeUARef}
                        />
                        <label className="form-label">Qtde. UA</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            value={codigoOrigem}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onChange={(event) => {
                                setCodigoOrigem(event.target.value)
                            }}
                            onKeyDown={(event) => handleKeyDown(event, validadeOrigemRef)}
                            ref={codigoOrigemRef}
                        />
                        <label className="form-label">Código</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={validadeOrigem}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onKeyDown={(event) => handleKeyDown(event, qtdeTransferirRef)}
                            ref={validadeOrigemRef}
                        />
                        <label className="form-label">Validade</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            value={qtdeTransferir}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onBlur={(event) => {
                                if (event.target.value > qtdeUA) {
                                    toast.error("Qtde a transferir não pode ser maior que a qtde. U.A.")
                                    setQtdeTransferir("")
                                    autoFoco(qtdeTransferirRef)
                                }
                            }}
                            onChange={(event) => {
                                setQtdeTransferir(event.target.value)
                            }}
                            onKeyDown={(event) => handleKeyDown(event, UADestinoRef)}
                            ref={qtdeTransferirRef}
                        />
                        <label className="form-label">Qtde. A transferir</label>
                    </div>

                    <div className="mb-1 text-center mt-2" style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                        <label className="form-label" style={{ paddingTop: '5px' }}>
                            <strong>DESTINO</strong>
                        </label>
                    </div>

                    <div className="row">
                        <div className="col-8 form-floating mt-2">
                            <input
                                type="number"
                                value={UADestino}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => setUADestino(event.target.value)}
                                onBlur={() => consultaUADestino(codigoDestinoRef, UADestino)}
                                onKeyDown={(event) => handleKeyDown(event, codigoDestinoRef)}
                                disabled={CamposDestinoDesabilitado}
                                ref={UADestinoRef}
                            />
                            <label className="form-label">U.A.</label>
                        </div>
                        <div className="col-4 form-check mt-2">
                            <label htmlFor="" className="form-label me-2" style={{ opacity: 0.7, fontSize: '0.7rem' }}>
                                U.A. Nova
                            </label>
                            <Switch
                                defaultChecked={false}
                                checked={UANovaCheck}
                                onChange={handleUaNovaChange}
                                ref={UANovaRef}
                            />
                        </div>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="number"
                            value={codigoDestino}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onKeyDown={(event) => handleKeyDown(event, validadeDestinoRef)}
                            disabled={CamposDestinoDesabilitado}
                            ref={codigoDestinoRef}
                        />
                        <label className="form-label">Código</label>
                    </div>

                    <div className="col form-floating mt-2">
                        <input
                            type="text"
                            value={validadeDestino}
                            className="form-control bordasInferiorInput text-black text-capitalize"
                            onKeyDown={(event) => handleKeyDown(event, btnDesfragmentarRef)}
                            disabled={CamposDestinoDesabilitado}
                            ref={validadeDestinoRef}
                        />
                        <label className="form-label">Validade</label>
                    </div>

                </Card.Body>
                <Card.Footer>

                    <div className="mb-2 mt-2 d-flex">
                        <div className="me-auto">
                            <button
                                type="button"
                                className="btn btn-cancelar me-2"
                                data-dismiss="modal"
                                hidden={viewBtnRomanear}
                                onClick={handleClickModalManualEVariado}
                            >
                                Romanear
                                <span className='me-1'> <TbListDetails size={15} /> </span>
                            </button>
                        </div>
                        <div className="ms-auto">
                            <button
                                type="button"
                                className="btn btn-novo"
                                ref={btnDesfragmentarRef}
                                onClick={() => {
                                    desfragmentar(UAOrigemRef)
                                }}
                            >
                                <span className='me-1'>Desfragmentar</span><i className="bi bi-check-lg"></i>
                            </button>
                        </div>
                    </div>

                </Card.Footer>
            </Card>

            <ModalManualEVariado
                isOpen={mostraModalManualEVariado}
                fecharModal={fecharModal}
                qtdeTransferir={qtdeTransferir}
                checkBarras={checkBarras}
                deleteRegTabTemporaria={deleteRegTabTemporaria}
                setBarra={setBarra}
                barra={barra}
                romaneados={romaneados}
            />

            <ModalVariadoBalanca
                isOpen={mostraModalVariavelBalanca}
                fecharModal={fecharModal}
                qtdeTransferir={qtdeTransferir}
                setPesoBruto={setPesoBruto}
                pesoEmbMultiplicado={pesoEmbMultiplicado}
                setPesoLiquido={setPesoLiquido}
                pesoLiquido={pesoLiquido}
            />
        </>
    )
}

export default Desfragmentar