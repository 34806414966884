import axios from "axios"

function CarregarPermissoes(id_perfil: string, id_filial: string, nivelPerfil: string) {

    return new Promise(function (resolve, reject) {

        if (nivelPerfil == "0") {
            id_filial = '0'
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/perfil/criado/${id_filial}/${id_perfil}`, {
            headers: {
                Authorization: sessionStorage.getItem("MultfilialWebToken")!
            }
        }).then(function (resposta) {

            resolve(resposta.data.perfilPermissao)
        }).catch(function (erro) {
            console.log("carregaPerm erro")
            reject(erro.response.data.message || erro.message)
        })
    })
}

export default CarregarPermissoes