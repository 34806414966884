import React, { useEffect } from 'react';
import { MdNoMeetingRoom } from 'react-icons/md';
const armazem = require("../../../assets/img/armazem.png") as string

interface AvatarFilialProps {
  avatarImgFilial: string | null;
  alt: string;
  className?: string;
  style?: React.CSSProperties
  nomeFilial?: string
}

const AvatarFilial: React.FC<AvatarFilialProps> = ({
  avatarImgFilial,
  alt,
  className,
  style,
  nomeFilial
}) => {
  
  return (
    <span className={`account-user-avatar ${className || ''}`} style={style}>
      {avatarImgFilial ? (
        <img
          src={`data:image/png;base64,${avatarImgFilial}`}
          alt={alt}
          className="rounded-action-icon imgEmpresa"
        />
      ) : nomeFilial ? (
        <span className="badge bg-primary mb-1 text-white fs-5">
          {nomeFilial.toUpperCase().replace(/['"]/g, '')}
        </span>
      ) : (
        <MdNoMeetingRoom className="icon-placeholder" />
      )}
    </span>
  );
};

export default AvatarFilial;
