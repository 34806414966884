import { useNavigate } from "react-router-dom"
import { getSessionData } from "../../utils/storageUtils"
import { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import ModalSelecionaReposicao from "./reposicaoPicking/components/modalSelecionaReposicao"

function MenuPrincipal() {
    const [viewBtn, setViewBtn] = useState<any>(false)
    const [mostraModalSelecionaReposicao, setMostraModalSelecionaReposicao] = useState<boolean>(false)

    const navigate = useNavigate()
    const idPerfil = getSessionData("id_perfil")

    useEffect(() => {
        if (idPerfil == '1') {
            setViewBtn(false)
        } else {
            setViewBtn(true)
        }
    }, [])


    //Página montada com botões de navegação 
    return (
        <>
            <Card className="mt-2">
                <div className="row mt-2 mx-auto text-center mt-md-4">
                    {/* <div className="mt-3 col-md-6"> */}
                    <div>
                        <button
                            type="button"
                            className="btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => navigate("/conferencia/saida")}
                        >
                            Conferência de Saída
                        </button>
                        <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => navigate("/conferencia/entrada")}
                        // hidden={viewBtn}
                        >
                            Conferência de Entrada
                        </button>
                        <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => navigate("/menu/enderecar")}
                        >
                            Endereçar
                        </button>
                        <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => navigate("/menu/picking")}
                        >
                            Picking
                        </button>
                    </div>
                    {/* <div className="col-md-6"> */}
                    <div>
                        <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => setMostraModalSelecionaReposicao(true)}
                        >
                            Ressuprimento
                        </button>
                        <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => navigate("/menu/desfragmentar")}
                        >
                            Desfragmentar
                        </button>
                        <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            hidden={viewBtn}
                        >
                            Inventário
                        </button>

                    </div>
                    {/* <div className="col-md-12"> */}
                    <div>
                        {/* <button
                            type="button"
                            className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                        >
                            Reposição Picking
                        </button> */}
                        <button
                            type="button"
                            className="mb-2 mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                            onClick={() => navigate("/acoes/menuAcoes")}
                        >
                            Outras Ações
                        </button>
                    </div>
                </div>
            </Card>

            <ModalSelecionaReposicao
                isOpen={mostraModalSelecionaReposicao}
                fecharModal={() => setMostraModalSelecionaReposicao(false)}
            />
        </>
    )
}

export default MenuPrincipal