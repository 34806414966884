import { Form, Link, useNavigate } from "react-router-dom";
import { getSessionData } from "../../utils/storageUtils";
import { useEffect, useState } from "react";

function MenuAdministrativo() {
    const [viewBtn, setViewBtn] = useState(true)

    const navigate = useNavigate()

    const idPerfil = getSessionData("id_perfil")

    function verificaPerfil() {
        if (idPerfil == '1') {
            setViewBtn(false)
        } else {
            setViewBtn(true)
        }
    }

    useEffect(() => {
        verificaPerfil()
    }, [])


    return (
        <>
            <div className="mt-5 text-center">
                <button
                    type="button"
                    className="btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                    onClick={() => navigate("/cadastro/filiais/filial")}
                    hidden={viewBtn}
                >
                    Filiais
                </button>
                {/* <button
                    type="button"
                    className="btn mt-3 text-light bg-primary border border-dark btn-lg btn-block w-75"
                    onClick={() => navigate("/config/segur/cadastroDePermissoes")}
                    hidden={viewBtn}
                >
                    Perfil/Permissão
                </button> */}
                <button
                    type="button"
                    className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                    onClick={() => navigate("/administrativo/usuario")}
                >
                    Usuário
                </button>
                <button
                    type="button"
                    className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                    onClick={() => navigate("/grid/filial")}
                    hidden={viewBtn}
                >
                    Usuarios/Filial
                </button>
                <button
                    type="button"
                    className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                    onClick={() => navigate("/administrativo/codistema")}
                    hidden={viewBtn}
                >
                    Código do Sistema
                </button>
            </div>
        </>
    )
}

export default MenuAdministrativo