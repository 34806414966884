import Logo from "../assets/img/logo_sm.png";

const FooterBar = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-md-end">
            {new Date().getFullYear()}©FKL Service Tecnologia - v1.0.0
            <img src={Logo} alt="" style={{ height: "14px", marginLeft: "5px" }} />
          </div>
          <div className="col-md-6 text-end">
            <div className="footer-links d-none d-md-block">
              {/* <a href="#">Sobre</a>
          <a href="#">Suporte</a>
          <a href="#">Contato</a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export { FooterBar };
