import axios from "axios";
import { useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getSessionData } from "../../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";
import Tabela from "../../../../components/Formularios/Table";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import ClickableTable from "../../../../components/ClickTable/ClickTable";
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";

interface ModalConsultaLocalProps {
    isOpen: boolean;
    fecharModal: () => void;
}

const ModalConsultaLocal: React.FC<ModalConsultaLocalProps> = ({
    isOpen,
    fecharModal
}) => {

    const [amz, setAmz] = useState<any>()
    const [corredor, setCorredor] = useState<any>()
    const [box, setBox] = useState<any>()
    const [dados, setDados] = useState([])
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

    const amzRef = useRef<HTMLInputElement>(null)
    const corredorRef = useRef<HTMLInputElement>(null)
    const boxRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate();

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    //Funções para controle de ações da tabela
    const editarUsuario = (dados: any) => { };
    const excluirUsuario = () => { };
    const visualizarUsuario = (dados: any) => { };

    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "Pallet", acesso: "id_ua" },
        { titulo: "Cliente", acesso: "fantasia" },
        { titulo: "Cod", acesso: "codigo" },
        { titulo: "Qtde", acesso: "qtde_disp" }
    ];

    function consultaLocal() {
        if (!amz) {
            toast.info("Insira o armazém para prosseguir")
            amzRef.current?.focus()
            setDados([])
            return;
        } else if (!corredor) {
            toast.info("Insira o corredor para prosseguir")
            corredorRef.current?.focus()
            setDados([])
            return;
        } else if (!box) {
            toast.info("insira o box para prosseguir")
            setDados([])
            return;
        }

        setMostraModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/acoes/consulta/${amz}/${corredor}/${box}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                    setDados([])
                }
            }
        }).finally(function () {
            setMostraModalCarregando(false)
        })
    }

    //Funçao para mudança de foco
    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    return (
        <>
            <Modal
                show={isOpen}
                className='mt-3'
                onShow={() => {
                    setAmz("")
                    setCorredor("")
                    setBox("")
                    setDados([])
                }}
            >
                <Modal.Header className="bg-white d-flex justify-content-between align-items-center" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Consultar Local"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={fecharModal}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <Row>
                        <div className="col form-floating mt-2">
                            <input
                                type="text"
                                value={amz}
                                autoFocus
                                className="form-control bordasInferiorInput text-black"
                                onChange={(event) => {
                                    setAmz(event.target.value)
                                }}
                                ref={amzRef}
                                onKeyDown={(event) => handleKeyDown(event, corredorRef)}
                            />
                            <label className="form-label">AMZ</label>
                        </div>

                        <div className="col form-floating mt-2">
                            <input
                                // type="text"
                                value={corredor}
                                className="form-control bordasInferiorInput text-black"
                                onChange={(event) => {
                                    setCorredor(event.target.value)
                                }}
                                ref={corredorRef}
                                onKeyDown={(event) => handleKeyDown(event, boxRef)}
                            />
                            <label className="form-label">CORREDOR</label>
                        </div>

                        <div className="col form-floating mt-2">
                            <input
                                type="number"
                                value={box}
                                className="form-control bordasInferiorInput text-black"
                                onChange={(event) => {
                                    setBox(event.target.value)
                                }}
                                onBlur={consultaLocal}
                                ref={boxRef}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        consultaLocal()
                                    }
                                }}
                            />
                            <label className="form-label">BOX</label>
                        </div>
                    </Row>


                    <Row className="mt-3">
                        {/* <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarUsuario}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                        /> */}

                        <ClickableTable
                            coluna={colunas}
                            data={dados}
                            itemsPerPage={10}
                            usaAcoes={false}
                            onRowClick={() => { }}
                        />
                    </Row>

                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="mb-2 d-flex justify-content-center w-100">
                        <button
                            type="button"
                            className="btn btn-cancelar me-2 w-50"
                            data-dismiss="modal"
                            onClick={() => {
                                fecharModal()
                            }}
                        >
                            Voltar
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalCarregando
                mostrar={mostraModalCarregando}
                mensagem="Carregando..."
            />
        </>
    )
}

export default ModalConsultaLocal