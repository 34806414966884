import { useEffect, useState } from "react"
import ModalSelecionaProcesso from "../components/ModalSelecionaProcesso"
import { toast } from "react-toastify";
import axios from "axios";
import { getSessionData, setSessionData } from "../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { Card, Row } from "react-bootstrap";
import Tabela from "../../../components/Formularios/Table";
import ModalBotoes from "../components/ModalBotoes";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import ClickableTable from "../../../components/ClickTable/ClickTable";
import ModalPadrao from "./components/ModalPadrao";
import ModalVarOP from "./components/ModalVarOP";
import ModalVarRomManual from "./components/ModalVarRomManual";
import ModalVarBal from "./components/ModalVarBal";
import ModalGtinPadrao from "./components/ModalGtinPadrao";

function PagPrincipalEntrada() {
    const [MostraModalSelecionaProcesso, setMostraModalSelecionaProcesso] = useState(false)
    const [mostralModalLoading, setMostraModalLoading] = useState(false)
    const [btnIniciar, setBtnIniciar] = useState(false)
    const [btnConcluir, setBtnConcluir] = useState(true)
    const [modalidade, setModalidade] = useState<any>()
    const [viewTableArmDev, setViewTableArmDev] = useState<boolean>(false)
    const [viewTableCrossRe, setViewTableCrossRe] = useState<boolean>(true)
    // const [processoInserido, setProcessoInserido] = useState<any>()
    const [mostraModalBotoes, setMostraModalBotoes] = useState<boolean>(false)
    const [dados, setDados] = useState([])
    const [mostraModalPadrao, setMostraModalPadrao] = useState<boolean>(false)
    const [mostraModalVarOP, setMostraModalVarOP] = useState<boolean>(false)
    const [mostraModalVarRomManual, setMostraModalVarRomManual] = useState<boolean>(false)
    const [mostraModalVarBal, setMostraModalVarBal] = useState<boolean>(false)
    const [mostraModalGtinPadrao, setMostraModalGtinPadrao] = useState<boolean>(false)
    const [WMS, setWMS] = useState<number>(0)
    const [codigo, setCodigo] = useState<string>("")
    const [descricao, setDescricao] = useState<string>("")
    const [tipoPeso, setTipoPeso] = useState<string>("")
    const [idCliente, setIdCliente] = useState<number>(0)
    const [gtinEntradaTodosPadrao, setGtinEntradaTodosPadrao] = useState<boolean>(false)
    const [gtinEntrada, setGtinEntrada] = useState<boolean>(false)
    const [qtdeFiscal, setQtdeFiscal] = useState<number>(0)
    const [pl, setPl] = useState<number>(0)
    const [exibirQtdeNF, setExibirQtdeNF] = useState<boolean>(false)
    const [unidade, setUnidade] = useState<string>('')
    const [aprovaValVencida, setAprovaValVencida] = useState<boolean>(false)

    const [colunasArmDev, setColunasArmDev] = useState<ITabela[]>([])

    const navigate = useNavigate()

    const token = getSessionData("MultfilialWebToken") ?? "";
    const idFilial = getSessionData("DadosIdfilial") ?? "";
    const usuarioLogado = getSessionData("NomeUsuario") ?? "";
    const perfilUsuario = getSessionData("PerfilUsuario") ?? "";

    const sessionData = getSessionData("ProcessoEntrada");
    const sessionProcesso = sessionData ? sessionData.replace(/"/g, '') : '';

    // const editarUsuario = (dados: any) => { };
    // const excluirUsuario = () => { };
    // const visualizarTabelaOS = (dados: any) => { };

    function consultaProcessoEntrada(processo: any) {
        if (processo == " " || processo == 0 || processo == undefined) {
            toast.error("O valor do processo não pode ser 0")
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/entrada/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setSessionData("ProcessoEntrada", resposta.data.processo)
            consultaViewTable(resposta.data.processo)
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data)
                setMostraModalSelecionaProcesso(true)
                setMostraModalLoading(false)
            }
        })
    }

    //Função para consultar conferencia e setar botão quando carrega a tela
    function consultaConferencia(processo: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/conferencia/entrada/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const conteudo = resposta.data[0].dt_inicio
            if (conteudo == null || conteudo == 0) {
                setBtnIniciar(false)
                setBtnConcluir(true)
            } else {
                setBtnIniciar(true)
                setBtnConcluir(false)
            }
        }).catch(function (erro) {
            console.error("Um erro inesperado aconteceu ao consultar conferência")
        })
    }

    function carregaGrid(processo: any, modalidade: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/grid/entrada/${processo}/${modalidade}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPl(resposta.data.pl)
            setQtdeFiscal(resposta.data.data[0].qtd_com)
            setIdCliente(resposta.data.data[0].id_cliente)
            if (resposta.data.viewColunas) {
                const novasColunas = [...colunasTableArmDev];
                novasColunas.push({ titulo: "Qtde. NFe", acesso: "qtd_com" });
                novasColunas.push({ titulo: "Unid", acesso: "unid_fiscal" });
                setColunasArmDev(novasColunas);
            } else {
                setColunasArmDev(colunasTableArmDev)
            }
            setDados(transformData(resposta.data.data))
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    const transformData = (data: any) => {
        return data.map((item: any) => {
            let tipoPeso = item.tipo_peso;
            switch (tipoPeso) {
                case 'Variado Balança':
                    tipoPeso = 'Var Balança';
                    break;
                case 'Var Rom Manual':
                    tipoPeso = 'Var Manual';
                    break;
                case 'Variado Optico':
                    tipoPeso = 'Var Optico';
                    break;
                case 'Padrão':
                    tipoPeso = 'Padrão'
                    break
                default:
                    break;
            }
            return {
                ...item,
                tipo_peso: tipoPeso
            };
        });
    };


    async function consultaViewTable(processo: any) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/view/table/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setModalidade(resposta.data.modalidade)
            if (resposta.data.modalidade == 'Armazenagem' || resposta.data.modalidade == 'Devolução') {
                setViewTableArmDev(false)
                setViewTableCrossRe(true)
            } else {
                setViewTableArmDev(true)
                setViewTableCrossRe(false)
            }
            carregaGrid(processo, resposta.data.modalidade)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    async function consultaConferirGtinPadrao(idCliente: string) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/conferirGtin/${idCliente}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data.data[0].gtin_entrada === true) {
                setMostraModalGtinPadrao(true)
                setMostraModalPadrao(false)
            } else {
                setMostraModalPadrao(true)
                setMostraModalGtinPadrao(false)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    function iniciarConferencia() {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/iniciar/conferencia/entrada?idFilial=${idFilial}`, {
            sessionProcesso
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            setBtnIniciar(true)
            setBtnConcluir(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error("Não foi possível iniciar a conferencia")
            }
        })
    }

    function concluirConferencia() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/concluir/conferencia/entrada?idFilial=${idFilial}`, {
            processo: sessionProcesso,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            console.log(resposta)
            toast.success(resposta.data)
            sessionStorage.removeItem("ProcessoEntrada")
            navigate("/menu/menuPrincipal")
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 308) {
                toast.error(erro.response.data)
                sessionStorage.removeItem("ProcessoEntrada")
                navigate("/menu/menuPrincipal")
            } else if (status == 409) {
                toast.error(erro.response.data)
            } else {
                toast.error(erro.response.data)
            }
        })
    }

    async function consultaRegrasDepositantes(idCliente: number) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/regras/depositante/${idCliente}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setGtinEntradaTodosPadrao(resposta.data.data[0].gtin_entrada_todos)
            setGtinEntrada(resposta.data.data[0].gtin_entrada)
            setExibirQtdeNF(resposta.data.data[0].confere_aberto)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    async function verificaUsuarioAprovaValVencida() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/usuario/aprovaVal/${perfilUsuario}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setAprovaValVencida(resposta.data.aprovaValVencida)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    const colunasTableArmDev: ITabela[] = [
        { titulo: "Código", acesso: "codigo" },
        { titulo: "Descrição", acesso: "descricao" },
        { titulo: "Tipo", acesso: "tipo_peso" }
    ];

    const colunasTableCrossRe: ITabela[] = [
        { titulo: "NF", acesso: "nf" },
        { titulo: "Destinatário", acesso: "razao_dest" },
        { titulo: "Vol", acesso: "vol" }
    ];

    function fecharModal() {
        setMostraModalSelecionaProcesso(false)
        setMostraModalBotoes(false)
        setMostraModalPadrao(false)
        setMostraModalVarOP(false)
        setMostraModalVarRomManual(false)
        setMostraModalVarBal(false)
    }

    function fecharModalGtin() {
        setMostraModalGtinPadrao(false)
    }

    useEffect(() => {
        if (sessionProcesso != "" && sessionProcesso) {
            setMostraModalSelecionaProcesso(false)
            consultaConferencia(sessionProcesso)
            consultaViewTable(sessionProcesso)
        } else {
            setMostraModalSelecionaProcesso(true)
        }
    }, [])

    const handleRowClick = async (dados: any) => {
        setWMS(dados.id_wms)
        setCodigo(dados.codigo)
        setTipoPeso(dados.tipo_peso)
        setDescricao(dados.descricao)
        setQtdeFiscal(dados.qtd_com)
        setUnidade(dados.unid_fiscal)

        await consultaRegrasDepositantes(dados.id_cliente)
        await verificaUsuarioAprovaValVencida()

        if (dados.tipo_peso == "Padrão") {
            consultaConferirGtinPadrao(dados.id_cliente)

        } else if (dados.tipo_peso == "Var Optico") {
            setMostraModalVarOP(true)
        } else if (dados.tipo_peso == "Var Manual") {
            setMostraModalVarRomManual(true)
        } else {
            setMostraModalVarBal(true)
        }
    }

    const onRowClick = () => { }

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    {/* <div className="row mb-2 border rounded border-fkl py-1 d-flex justify-content-between align-items-center">
                        <div className="col-6">
                            <label className="m-0"><i>Processo: {sessionProcesso}</i></label>
                        </div>
                        <div className="col-6">
                            <button 
                            className="w-100 btn text-light bg-primary border border-dark rounded" 
                            onClick={() => setMostraModalSelecionaProcesso(true)}
                            >
                                <i className="bi bi-arrow-clockwise"></i> Trocar Processo
                            </button>
                        </div>
                    </div> */}
                    <div className="row mb-3">
                        <div className="col-6">
                            <button
                                type="button"
                                hidden={btnIniciar}
                                className="w-100 py-1 btn btn-danger btn-block rounded"
                                onClick={iniciarConferencia}
                            >
                                Iniciar
                            </button>
                            <button
                                type="button"
                                hidden={btnConcluir}
                                // hidden={true}
                                className="w-100 py-1 btn btn-cancelar btn-block rounded"
                                onClick={concluirConferencia}
                            >
                                Concluir
                            </button>
                        </div>

                        <div className="col-6">
                            {/* <button type="button" className='w-100 py-0  btn btn-cancelar btn-block rounded'>Concluir</button> */}
                            <button
                                type="button"
                                className='w-100 py-1 btn text-light bg-primary border border-dark btn-block rounded'
                                onClick={() => setMostraModalBotoes(true)}
                            >
                                Ações
                            </button>
                        </div>
                    </div>
                    {/* <Row hidden={viewTableArmDev}>
                        <Tabela
                            coluna={colunasTableArmDev}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            viewAcoesConfSaida={true}
                        />
                    </Row> */}

                    {/* <Row className="mt-1" hidden={viewTableArmDev}>
                        <ClickableTable
                            coluna={colunasTableArmDev}
                            itemsPerPage={10}
                            data={dados}
                            onRowClick={handleRowClick}
                            viewFilter={false}
                        />
                    </Row> */}

                    {
                        viewTableArmDev && (
                            <>
                                <ClickableTable
                                    coluna={colunasArmDev}
                                    itemsPerPage={10}
                                    data={dados}
                                    onRowClick={onRowClick}
                                />
                            </>
                        )
                    }
                    {
                        !viewTableArmDev && (
                            <>
                                <ClickableTable
                                    coluna={colunasArmDev ? colunasArmDev : colunasTableArmDev}
                                    itemsPerPage={10}
                                    data={dados}
                                    onRowClick={handleRowClick}
                                    labelpesquisa="Filtrar pelo código"
                                    acessopesquisa="codigo"
                                />
                            </>
                        )
                    }



                    {/* <Row hidden={viewTableCrossRe}>
                        <Tabela
                            coluna={colunasTableCrossRe}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            viewAcoesConfSaida={true}
                        />
                    </Row> */}

                    {/* <Row hidden={viewTableCrossRe}>
                        <ClickableTable
                            coluna={colunasTableCrossRe}
                            itemsPerPage={10}
                            data={dados}
                            onRowClick={onRowClick}
                            viewFilter={true}
                        />
                    </Row> */}
                </Card.Body>
            </Card>

            <ModalLoading show={mostralModalLoading} />

            <ModalBotoes
                isOpen={mostraModalBotoes}
                fecharModal={fecharModal}
                processo={sessionProcesso}
                tipoConferencia="Entrada"
                tipoOperacao={modalidade}
                permiteDivergencia={true}
            />

            <ModalSelecionaProcesso
                isOpen={MostraModalSelecionaProcesso}
                consultaProcesso={consultaProcessoEntrada}
                idFilial={idFilial}
                token={token}
                consultaConferencia={consultaConferencia}
                fecharModal={fecharModal}
            />

            <ModalGtinPadrao
                isOpen={mostraModalGtinPadrao}
                fecharModal={fecharModalGtin}
                WMS={WMS}
                token={token}
                idFilial={idFilial}
                idCliente={idCliente}
                setMostraModalPadrao={setMostraModalPadrao}
            />

            <ModalPadrao
                isOpen={mostraModalPadrao}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                token={token}
                idFilial={idFilial}
                usuarioLogado={usuarioLogado}
                idCliente={idCliente}
                gtinEntradaTodos={gtinEntradaTodosPadrao}
                gtinEntrada={gtinEntrada}
                unFiscal={unidade}
                qtdeFiscal={qtdeFiscal}
                exibirQtdeNF={exibirQtdeNF}
                aprovaValVencida={aprovaValVencida}
            />

            <ModalVarOP
                isOpen={mostraModalVarOP}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                idFilial={idFilial}
                token={token}
                usuarioLogado={usuarioLogado}
                gtinEntradaTodos={gtinEntradaTodosPadrao}
                gtinEntrada={gtinEntrada}
                unFiscal={unidade}
                qtdeFiscal={qtdeFiscal}
                idCliente={idCliente}
                openVarBalanca={setMostraModalVarBal}
                exibirQtdeNF={exibirQtdeNF}
                aprovaValVencida={aprovaValVencida}
            />

            <ModalVarRomManual
                isOpen={mostraModalVarRomManual}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                idFilial={idFilial}
                token={token}
                usuarioLogado={usuarioLogado}
                gtinEntradaTodos={gtinEntradaTodosPadrao}
                gtinEntrada={gtinEntrada}
                unFiscal={unidade}
                qtdeFiscal={qtdeFiscal}
                idCliente={idCliente}
                openVarBalanca={setMostraModalVarBal}
                exibirQtdeNF={exibirQtdeNF}
                aprovaValVencida={aprovaValVencida}
            />

            <ModalVarBal
                isOpen={mostraModalVarBal}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                token={token}
                idFilial={idFilial}
                usuarioLogado={usuarioLogado}
                gtinEntradaTodos={gtinEntradaTodosPadrao}
                gtinEntrada={gtinEntrada}
                unFiscal={unidade}
                qtdeFiscal={qtdeFiscal}
                idCliente={idCliente}
                exibirQtdeNF={exibirQtdeNF}
                aprovaValVencida={aprovaValVencida}
            />
        </>

    )
}

export default PagPrincipalEntrada