import { ChangeEvent, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import ClickableTable from "../../../components/ClickTable/ClickTable";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getSessionData } from "../../../utils/storageUtils";
import convertDataBRParaEUA from "../../../components/functions/convertDataBRParaEUA";
import ModalCarregando from "../../../components/ModalCarregando/ModalCarregando";
import ModalEnderecar from "./components/modalEnderecar";

function ReposicaoPrimaria() {

    const [dataCarga, setDataCarga] = useState<string>()
    const [dados, setDados] = useState<any[]>([])
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)
    const [mostraModalEnderecar, setMostraModalEnderecar] = useState<boolean>(false)
    const [UA, setUA] = useState<number>(0)

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    const navigate = useNavigate()
    const { idCliente } = useParams()

    const colunas: ITabela[] = [
        { titulo: 'Código', acesso: 'codigo' },
        { titulo: 'U.A', acesso: 'id_ua' },
        { titulo: 'Endereço U.A', acesso: 'endereco_ua' },
        { titulo: 'Endereço Picking', acesso: 'endereco_picking' },
        { titulo: 'Descrição', acesso: 'descricao' }
    ];

    const handleDataCargaChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Remove todos os caracteres não numéricos
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 8) {
            // Limita a entrada a 8 caracteres
            input = input.slice(0, 8);
        }

        if (input.length <= 2) {
            input = input;
        } else if (input.length <= 4) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        } else {
            input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
        }

        setDataCarga(input);
    };

    async function gerarCalculo() {
        if (!idCliente || !dataCarga) {
            return toast.info("Preencha todas as informações para prosseguir")
        }

        const newDataCarga = convertDataBRParaEUA(dataCarga)

        setMostraModalCarregando(true)
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/gerarCalculo/reposicaoPrimaria`, {
            idFilial,
            idCliente,
            newDataCarga
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            console.log(resposta)
            carregarDadosGrid()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    async function carregarDadosGrid() {
        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregarDados/reposicaoPrimaria/${idFilial}/${idCliente}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success("Cálculo gerado com sucesso")
            setDados(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setDados([])
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    function onRowClick(dados: any) {
        setUA(dados.id_ua)
        setMostraModalEnderecar(true)
    }

    return (
        <>
            <Card className="mt-2">
                <Card.Body>

                    <Row className="justify-content-center justify-content-md-start">
                        <Col xs={12} md={4}>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                    value={dataCarga}
                                    onChange={handleDataCargaChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            gerarCalculo()
                                        }
                                    }}
                                />
                                <label className="form-label">Data de Carga Pedidos</label>
                            </div>
                        </Col>

                        <Col xs={8} md={4} className="mt-3 ">
                            <button
                                type="button"
                                className="btn btn-novo w-100 rounded"
                                onClick={gerarCalculo}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        gerarCalculo()
                                    }
                                }}
                            >
                                <span className="me-1">Gerar cálculo</span>
                                <i className="bi bi-check-lg"></i>
                            </button>
                        </Col>

                        {/* <Col xs={6} md={4} className="mt-3 ">
                            <button
                                type="button"
                                className="btn btn-novo w-100 rounded"
                            // onClick={gerarCalculo}
                            >
                                <span className="me-1">Endereçar</span>
                                <i className="bi bi-geo-fill"></i>
                            </button>
                        </Col> */}
                    </Row>

                    <Row className="mt-4">
                        <ClickableTable
                            coluna={colunas}
                            itemsPerPage={10}
                            data={dados}
                            onRowClick={onRowClick}
                        />
                    </Row>

                </Card.Body>
            </Card>

            <ModalCarregando
                mostrar={mostraModalCarregando}
                mensagem="Carregando..."
            />

            <ModalEnderecar
                isOpen={mostraModalEnderecar}
                fecharModal={() => {
                    setMostraModalEnderecar(false)
                    carregarDadosGrid()
                }}
                UA={UA}
                idCliente={idCliente || 0}
            />
        </>
    )
}

export default ReposicaoPrimaria