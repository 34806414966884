import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";
import { toast } from "react-toastify";
import ApiGet from "../../../api/endPoints/useGet";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import Swal from "sweetalert2";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { getSessionData } from "../../../utils/storageUtils";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import axios from "axios";

export function Usuario() {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  let isVinculo = getSessionData("isPerfilVinculo") || "";
  const id_perfil = getSessionData("id_perfil")
  const idUsuario = getSessionData("DadosUsuarioID")
  const navigate = useNavigate();

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/administrativo/usuario/usuario/novo")}
        />
      ),
      name: "Incluir",
    }
  ];

  const visualiarUsuario = (dados: any) => {
    const id_usuario = dados.id_usuario;
    navigate("/administrativo/usuario/usuario/visu/" + id_usuario);
  };

  const editarUsuario = (dados: any) => {
    const id_usuario = dados.id_usuario;
    navigate("/administrativo/usuario/usuario/editar/" + id_usuario);
  };

  const excluirUsuario = async (dados: any) => {
    const id_usuario = dados.id_usuario;
    await showConfirmationDialog(
      "Excluir Usuário",
      `Tem certeza que deseja excluir o usuário \n ${dados.nome}?`,
      "question"
    )
      .then((result) => {
        if (result.confirmed) {
          const headers = {
            Authorization: token,
          };
          const dados = {
            url: `/usuario/excluir/?id_usuario=${id_usuario}&vinculo=${isVinculo}`,
            parametros: {},
            headers,
          };
          console.log("dados: ", dados);
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados(isSuper);
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_usuario" },
    { titulo: "Nome", acesso: "nome" },
    { titulo: "Usuário", acesso: "nome_usuario" },
    { titulo: "Email", acesso: "email" },
    { titulo: "Telefone", acesso: "telefone" },
    { titulo: "Perfil", acesso: "nome_perfil" }
    // { titulo: "Situação", acesso: "situacao" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState<InterfaceUsuario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const buscarDados = async (master: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    if (id_perfil == "1") {
      isVinculo = "0"
    }
    const dados = {
      // url: `/usuario/listar/?master=${master}&vinculo=${isVinculo || "0"}`,
      url: `/usuario/listar/?idPerfil=${id_perfil}&idUsuario=${idUsuario}`,
      parametros: {

      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao listar usuários. Motivo: ${erro.message}`);
      });
  };

  //verifica permissão --- inicio
  const [permissao_visualizar, set_permissao_visualizar] = useState(true)
  const [permissao_incluir, set_permissao_incluir] = useState(true)
  const [permissao_editar, set_permissao_editar] = useState(true)
  const [permissao_excluir, set_permissao_excluir] = useState(true)

  // async function CarregarPermissões(id_perfil: any) {
  //   setLoading(true);
  //   const headers = {
  //     Authorization: token,
  //   };
  //   const dados = {
  //     url: "/perfil/carregar/:idperfil",
  //     parametros: {
  //       idperfil: id_perfil,
  //     },
  //     headers: headers,
  //   };
  //   ApiGet(dados).then(function (response) {
  //     console.log(response)
  //     if (response[0].visualizar_usuario === 1) {
  //       set_permissao_visualizar(false)
  //     }
  //     if (response[0].incluir_usuario === 1) {
  //       set_permissao_incluir(false)
  //     }
  //     if (response[0].editar_usuario === 1) {
  //       set_permissao_editar(false)
  //     }
  //     if (response[0].excluir_usuario === 1) {
  //       set_permissao_excluir(false)
  //     }


  //   })
  // }
  //verifica permissão --- fim

  // Função para realizar a busca
  const [busca, set_busca] = useState("")
  function Search(string: any) {
    // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
    let termoBusca = string.toLowerCase();

    if (termoBusca == "") {
      buscarDados(isSuper)
    }
    else {
      // Use a função filter para encontrar as pessoas que correspondem à busca
      const resultados = dados.filter(function (info: any) {

        // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
        let buscando = info.nome.toLowerCase()

        // Verifique se contém o termo de busca
        return buscando.includes(termoBusca)

      })


      setDados(resultados)
    }
  }

  function carregaPermissoes() {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/usuario/carrega/permissoes/${id_perfil}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {
      const response = resposta.data.data[0]

      set_permissao_visualizar(!response.visualizarUsuario)
      set_permissao_editar(!response.editarUsuario)
      set_permissao_excluir(!response.excluirUsuario)
      set_permissao_incluir(!response.criarUsuario)
    }).catch(function (erro) {
      const status = erro.response.status
      if (status == 403 || status == 401) {
        toast.error("Acesso negado, faça seu login novamente")
        navigate("/login")
      } else if (status == 406) {
        toast.info(erro.response.data)
      }
    })
  }

  useEffect(function () {
    Search(busca)
  }, [busca])

  useEffect(() => {
    // CarregarPermissões(id_perfil)
    carregaPermissoes()
    buscarDados(isSuper);
  }, []);

  //#endregion

  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Usuários" />

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <SpeedDial
                    hidden={permissao_incluir}
                    ariaLabel="SpeedDial basic example"
                    direction="left"
                    sx={{
                      right: 0,
                      position: "absolute",
                    }}
                    icon={<SpeedDialIcon />}
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                      />
                    ))}
                  </SpeedDial>
                </Row>
                <Row>
                  <div className="d-flex my-2 gap-1 ">
                    <Col sm={8}>
                      <InputSemBorda
                        name="pesquisa"
                        type="text"
                        value={busca}
                        onChange={set_busca}
                        classNameInputsSemBorda="form-control bordasInferiorInput"
                        placeholder=" "
                        label="Procurar por nome"
                      />
                    </Col>
                    {/*<Col sm={3}>
                      <InputSemBorda
                        name="pesquisa"
                        type="text"
                        value=""
                        onChange={() => { }}
                        classNameInputsSemBorda="form-control bordasInferiorInput"
                        placeholder=" "
                        label="Perfil"
                      />
                    </Col> */}
                  </div>
                </Row>

                <Row>
                  <Tabela
                    coluna={colunas}
                    dados={dados}
                    itemsPerPage={10}
                    onVisuClick={visualiarUsuario}
                    onEditClick={editarUsuario}
                    onDeleteClick={excluirUsuario}
                    onBaixarClick={() => { }}
                    id="codigo"
                    permissao_editar={permissao_editar}
                    permissao_visualizar={permissao_visualizar}
                    permissao_excluir={permissao_excluir}
                    permissao_baixar={true}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label="Carregando Listagem de Usuários" />
    </>
  );
}
