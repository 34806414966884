import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getSessionData } from "../../../../utils/storageUtils";

interface ModalSelecionaReposicaoProps {
    isOpen: boolean;
    fecharModal: () => void;
}

const ModalSelecionaReposicao: React.FC<ModalSelecionaReposicaoProps> = ({
    isOpen,
    fecharModal
}) => {

    const [idCliente, setIdCliente] = useState<number>()
    const [depositantes, setDepositantes] = useState<string[]>([])

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    const navigate = useNavigate()

    async function buscaDepositantes() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/depositantes/reposicaoPrimaria/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setDepositantes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    return (
        <>
            <Modal
                show={isOpen}
                onShow={() => {
                    setIdCliente(0)
                    setDepositantes([])
                    
                    buscaDepositantes()
                }}
                centered
            >
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Selecione Depositante e Reposição"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <Row className="justify-content-center mb-2">
                        <Col sm={8}>
                            <div className="form-floating">
                                <select
                                    value={idCliente}
                                    onChange={(e) => setIdCliente(parseInt(e.target.value))}
                                    className="form-select bordasInferiorInput text-black"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                >
                                    <option value="">Selecione...</option>
                                    {depositantes.map(function (depositante: any) {
                                        return (
                                            <option
                                                key={depositante.id_cliente}
                                                value={depositante.id_cliente}
                                            >
                                                {depositante.fantasia}
                                            </option>
                                        );
                                    })}
                                </select>
                                <label>Depositante</label>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <Row className="w-100">
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <button
                                type="button"
                                className='w-100 text-center btn btn-novo btn-block rounded'
                                onClick={() => {
                                    if (!idCliente) {
                                        return toast.info("Selecione o depositante para prosseguir")
                                    }
                                    navigate(`/menu/reposicaoPrimaria/${idCliente}`)
                                    fecharModal()
                                }}
                            >
                                <span className='me-2'>Primária</span><i className="bi bi-1-circle-fill"></i>
                            </button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <button
                                type="button"
                                className='w-100 text-center btn btn-novo btn-block rounded'
                                onClick={() => {
                                    if (!idCliente) {
                                        return toast.info("Selecione o depositante para prosseguir")
                                    }

                                    fecharModal()
                                }}
                            >
                                <span className='me-2'>Secundária</span><i className="bi bi-box-fill"></i>
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalSelecionaReposicao